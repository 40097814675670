import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  padding: 10px;
  background-color: #343a40;
  color: white;
  text-align: center;
`;

const Footer = () => (
  <FooterContainer>
    <p>© 2022 Viatouch Media Inc. All rights reserved.</p>
  </FooterContainer>
);

export default Footer;
