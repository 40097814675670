import React from "react";
import { firstRow, examples } from "./data";
import GetVickiBlack from "../../assets/images/getvicki_black.png";
import getVickiLogo from "../../assets/images/getVICKI_logo.png";
import AppStore from "../../assets/images/Appstore.png";
import PlayStore from "../../assets/images/Playstore.png";
import Column from "../../components/Column";
import GetVickiStore from "../../components/GetVickiStore";
import GetVickiFeatures from "../../components/GetVickiFeatures";
import GetVickiMore from "../../components/GetVickiMore";
const GetVickiApp = (props) => {
	return (
		<Column padding="15vh 0 0">
			<GetVickiStore
				getVickiBlack={GetVickiBlack}
				getVickiLogo={getVickiLogo}
				appStore={AppStore}
				playStore={PlayStore}
			/>
			<GetVickiFeatures firstRow={firstRow} examples={examples} />
			<GetVickiMore />
		</Column>
	);
};

export default GetVickiApp;
