import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Link } from "react-router-dom";
import tos from "../assets/privacy_policy.pdf";
import laa from "../assets/licensing_and_agreement.pdf";
import Row from "./Row";

const StyledLink = styled(Link)`
	text-decoration: none;
	color: white;
`;

const A = styled.a`
	text-decoration: none;
	color: white;
	outline: none;

	@media only screen and (max-width: 1292px) {
		align-self: center;
		padding-top: 1em;
	}
`;

const Ul = styled.ul`
	list-style-type: none;
	padding-right: 1em;
`;

const Li = styled.li`
	padding-bottom: 1em;
`;

const FooterNav = (props) => {
	const isBigScreen = useMediaQuery({ query: "(min-width: 868px)" });

	const renderFullView = () => {
		return (
			<Row>
				<Ul>
					<Li>
						<StyledLink to="/">Home</StyledLink>
					</Li>
					<Li>VICKI Features</Li>
					<Li>
						<Ul>
							<Li>
								<StyledLink to="features">Standard Features</StyledLink>
							</Li>
							<Li>
								<StyledLink to="uvc">UV-C Sanitize Tech</StyledLink>
							</Li>
							<Li>
								<StyledLink to="computer_vision">Computer Vision</StyledLink>
							</Li>
							<Li>
								<StyledLink to="vicki_verify">Verify ID & Age</StyledLink>
							</Li>
							<Li>
								<StyledLink to="pickup">Order PickUp</StyledLink>
							</Li>
						</Ul>
					</Li>
					<Li>
						<StyledLink to="/terms-conditions">
							{" "}
							Terms of Service{" "}
						</StyledLink>
					</Li>
					<Li>
						<StyledLink to="/licensing-agreements">
							{" "}
							Licensing and Agreement{" "}
						</StyledLink>
					</Li>
				</Ul>
				<Ul>
					<Li>
						<StyledLink to="get_a_demo">Get a Demo</StyledLink>
					</Li>
					<Li>
						<StyledLink to="getvicki_app">VICKI Rewards App</StyledLink>
					</Li>
					{/* <Li>Other Products</Li> */}
					<Li>Company</Li>
					<Li>
						<Ul>
							<Li>
								<StyledLink to="about">About ViaTouch</StyledLink>
							</Li>
							<Li>
								<StyledLink to="customers">Customers</StyledLink>
							</Li>
							<Li>
								<StyledLink to="media_press">Media & Press</StyledLink>
							</Li>
							{/* <Li>Acquire Customers</Li> */}
							<Li>
								<StyledLink to="contact_us">Contact Us</StyledLink>
							</Li>
							{/* <Li>Careers</Li> */}
						</Ul>
					</Li>
				</Ul>
			</Row>
		);
	};

	const renderMobileView = () => {
		return (
			<StyledLink to='/terms-conditions'>
				Terms of Service
			</StyledLink>
		);
	};

	return (
		<Row justifyContent="center">
			{isBigScreen ? renderFullView() : renderMobileView()}
		</Row>
	);
};

export default FooterNav;
