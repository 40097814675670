import React from "react";
import styled from "styled-components";
import Column from "./Column";

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 151px;
	height: 138px;
	background-color: #d8d8d8;
	margin-bottom: 2em;
`;

const Img = styled.img`
	width: 100px;
	height: 100px;
`;

const Caption = styled.span`
	text-align: center;
	font-size: 20px;
`;

const ExampleIcon = (props) => {
	return (
		<Column alignItems="center" margin="3em 0 0 0" width="300px">
			<Container>
				<Img src={props.image} />
			</Container>
			<Caption>{props.caption}</Caption>
		</Column>
	);
};

export default ExampleIcon;
