import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Video from "./Video";
import CVDemo from "../assets/videos/cv_demo_web.mp4";

const Header = styled.span`
	font-size: 55px;
	font-weight: 300;
	margin-bottom: 0.5em;

	@media only screen and (max-width: 1030px) {
		text-align: center;
	}
`;

const BR = styled.p`
	margin: 0;
`;

const P = styled.p`
	font-size: 18px;
	line-height: 1.5;
	letter-spacing: 0.71px;
	margin-bottom: 3em;
`;
const CVDiv = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1030px)" });
	return (
		<Column
			margin={!isSmallScreen && "15vh 0 0 0"}
			width={!isSmallScreen && "35%"}
			padding={isSmallScreen && "15vh 1em 0 1em"}
		>
			{isSmallScreen ? (
				<Header>
					Computer Vision with <img src={props.vickiText} />
				</Header>
			) : (
				<Header>
					Computer Vision
					<BR>
						with <img src={props.vickiText} />
					</BR>
				</Header>
			)}
			<P>
				Computer vision helps in core tasks such as theft prevention, inventory
				management, detecting suspicious behavior and consumer data analytics.
				It uses live feed data from cameras to receive critical data and make
				decisions to provide actionable insights. Deduced data from computer
				vision systems aid in identifying customer behaviors and patterns in
				real-time.
			</P>

			<Video
				controls={true}
				src={CVDemo}
				type="video/mp4"
				width="100%"
				height="50%"
			/>
		</Column>
	);
};

export default CVDiv;
