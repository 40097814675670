import React from "react";
import styled from "styled-components";
import Column from "./Column";

const Img = styled.img`
	margin: 2em 3em 1em 3em;

	@media only screen and (max-width: 610px) {
		margin: 0;
	}
`;

const Bold = styled.span`
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0.6px;
`;

const P = styled.p`
	margin-bottom: 0.5em;
`;

const Container = styled.div`
	margin-left: 3em;
`;

const CVImage = (props) => {
	return (
		<Column>
			<Img src={props.image} />
			<Container>
				<Bold>{props.caption}</Bold>
				{props.details.map((detail) => (
					<P key={detail}>{detail}</P>
				))}
			</Container>
		</Column>
	);
};

export default CVImage;
