import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";

const H1 = styled.span`
	font-size: 56px;
	font-weight: light;
	line-height: 1.15;
	letter-spacing: 0.57px;

	@media only screen and (max-width: 640px) {
		text-align: center;
	}
`;
const BR = styled.p`
	margin: 0;
`;
const P = styled.p`
	font-size: 18px;
	line-height: 1.5;
	letter-spacing: 0.71px;
	margin-bottom: ${(props) => (props.margin ? props.margin : "0")};
`;

const Ol = styled.ol`
	list-style: none;
	counter-reset: item;
	padding: 0;
	margin: 0;
`;

const Li = styled.li`
	margin-bottom: 0.5em;
	font-size: 18px;
	line-height: 1.5;
	letter-spacing: 0.71px;

	&::before {
		content: counter(item) ") ";
		counter-increment: item;
	}
`;

const Img = styled.img`
	padding-top: 5em;

	@media only screen and (max-width: 640px) {
		padding-top: 2em;
		width: 70vw;
		height: 70vw;
	}
`;

const VickiVerifyDiv = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });
	return (
		<Row wrap="wrap" justifyContent="center">
			<Column
				width={!isMobile && "600px"}
				padding={isMobile && "1em 1em"}
				alignItems={isMobile && "center"}
			>
				{isMobile ? (
					<H1>
						Verify ID & Age with <img src={props.vickiText} />
					</H1>
				) : (
					<H1>
						Verify ID & Age{" "}
						<BR>
							with <img src={props.vickiText} />
						</BR>
					</H1>
				)}
				<P margin="1em">
					Customers can add their ID to become VICKI Verified. VICKI Verified
					members have had their ID (state ID or license) validated and
					confirmed against their face and their Credit Card. This process
					essentially eliminates Credit Card Fraud.
				</P>
				<P margin="0.5em">The process for customers is simple:</P>
				<Ol>
					<Li>Upload photo of front of ID</Li>
					<Li>Upload photo of back of ID</Li>
					<Li>Take selfie with ID in frame</Li>
				</Ol>
			</Column>
			<Img src={props.VickiVerifyPhone} />
		</Row>
	);
};

export default VickiVerifyDiv;
