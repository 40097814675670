import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";
import Button from "./Button";

const Header = styled.span`
	font-size: 36px;
	letter-spacing: 1.38px;
	color: #88a94f;
	margin-bottom: 1em;
	@media only screen and (max-width: 1000px) {
		text-align: center;
	}
`;

const P = styled.p`
	line-height: 2em;
	margin: 0 0 1em 0;
`;

const Img = styled.img`
	height: auto;
	width: auto;

	@media only screen and (max-width: 1292px) {
		height: 350px;
		width: 350px;
	}
`;

const StoreDiv = (props) => {
	const isBigScreen = useMediaQuery({ query: "(min-width: 1000px)" });
	return (
		<Column alignItems="center" margin="5em 0 3em 0">
			<Row wrap="wrap" justifyContent="center">
				<Column
					width={isBigScreen && "25%"}
					margin={isBigScreen && "0 10em 0 0"}
					padding={!isBigScreen && "0 1em"}
				>
					<Header>A Store Within a Store</Header>
					<P>
						VICKI is an IOT self-checkout solution for retail. Powered by
						artificial intelligence and other advanced technologies, VICKI is a
						modern replacement for traditional vending experiences and the first
						retail solution to leverage AI at the point-of -sale. She takes the
						POS experience to the next level in an Amazon and Google world.
					</P>
					<P>
						VICKI’s sleek back and front facing displays and directional audio
						make her a truly disruptive solution to the retail challenges of the
						21st century. VICKI can understand her customers’ habits,
						preferences, and even questions. She can be placed anywhere you like,
						and loves to be in the middle of the crowd!
					</P>
					<P>
						Traditional solutions alike suffer from many business challenges.
						Often businesses have to combat theft, deal with complex inventory
						systems, and managing transactions and payment systems. VICKI’s
						auto-retail solutions streamline and eliminate those challenges by
						combining artificial intelligence with on-board hardware (thumb
						scan, screen, and NFC) and dedicated software system for managing
						all of your administrative needs.
					</P>
				</Column>
				<Column alignItems="center">
					<Link to="uvc">
						<Img src={props.src} alt={props.alt} />
					</Link>
				</Column>
			</Row>
			<Button link="true" to="features">
				Features
			</Button>
		</Column>
	);
};

export default StoreDiv;
