import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

const Delivery = (props) => {
    useEffect(() => {
        window.DCOM.init({
            'authorize': 'guest',
            'api_key': 'ODQ0OWU3MTU5NDc4MzQ3ZTY4MjlmOTY3MjA0Y2Q3Y2M2',
        });
        window.DCOM.embed('Search', {
            'group_id' : '308487'
        });
    }, [])
    return (
        <div style={{marginTop: "20vh"}}>
            <script id="dcom-sdk-container">
            </script>
        </div>
    )
};

export default Delivery;
