import iPhoneReceipts from "../../assets/images/iphone_receipts.png";
import iPhoneLocal from "../../assets/images/iphone_local.png";
import iPhoneSearch from "../../assets/images/iphone_search.png";
import iPhoneBio from "../../assets/images/iphone_bio.png";
import iPhonePromos from "../../assets/images/iphone_promos.png";
import iPhoneChat from "../../assets/images/iphone_chat.png";
import iPhonePoints from "../../assets/images/iphone_points.png";
import iPhoneVerify from "../../assets/images/iphone_verify.png";
import iPhoneAge from "../../assets/images/iphone_age.png";
import iPhoneOrder from "../../assets/images/iphone_order.png";

export const firstRow = [
	[
		iPhoneReceipts,
		"Check Receipts",
		"Get receipts for your purchases and download them for future reference.",
	],
	[
		iPhoneLocal,
		"Find local VICKIs",
		"Locate your nearest VICKI and see what is in them.",
	],
	[
		iPhoneSearch,
		"Search VICKIs",
		"Find out which VICKI has the items you want.",
	],
	[
		iPhoneBio,
		"Add biometrics",
		"Allow iris and thumb scanning for convenience and secure interactions.",
	],
];

export const examples = [
	[
		iPhonePromos,
		"Find promos & discounts",
		"Get your stuff for less! Find VICKIs that have what you want on sale.",
	],
	[
		iPhoneChat,
		"Chat with VICKI",
		"Have a question? Chat with VICKI and find out answers.",
	],
	[
		iPhonePoints,
		"VICKI Points",
		"Get rewards points while shopping and redeem them to get money off your VICKI purchases.",
	],
	[
		iPhoneVerify,
		"VICKI Verify Program",
		"Eliminate the ability for criminals to use stolen cards by confirming the card belongs to the consumer.",
	],
	[
		iPhoneAge,
		"Age Verification",
		"After qualifying the customer we can confirm their age and provide access to age-gated products",
	],
	[
		iPhoneOrder,
		"Order for Pickup",
		"Order food items or other products ahead of time and pick them up from a specific VICKI",
	],
];
