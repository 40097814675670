import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";

const Header = styled.span`
	font-size: 80px;
	font-weight: 300;
	line-height: 1.15;
	letter-spacing: 0.57px;
	align-self: flex-start;
	margin-bottom: 0.5em;
`;

const H2 = styled.span`
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 0.5em;
`;

const P = styled.p`
	font-size: 14px;
	margin: 0;
`;

const Img = styled.img`
	margin-top: 10em;
	margin-left: auto;

	@media only screen and (max-width: 1420px) {
		width: 30vw;
		height: 40vh;
		align-self: flex-end;
	}
`;

const ContactDiv = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1120px)" });

	const renderFullView = () => {
		return (
			<Row>
				<Column
					margin="15vh 0 5em 30em"
					justifyContent="center"
					alignItems="center"
					width="35%"
				>
					<Header>Get in touch!</Header>
					<Row wrap="wrap" alignItems="baseline" margin="0 0 4em 0">
						<Column width="300px" margin="0 0 5em 0">
							<H2>Sales (US / International)</H2>
							<P>
								<strong>Jim Sirignano</strong>
							</P>
							<P>
								<strong>Chief Revenue Officer</strong>
							</P>
							{/* <P>813-758-4300</P> */}
							<P>jimsirignano@viatouchmedia.com</P>
						</Column>
						<Column margin="0 2em 5em 0" width="200px">
							<H2>General Inquiries & Customer Support</H2>
							<P>1-866-942-0804</P>
						</Column>
						<Column width="300px">
							<H2>For media or partnerships, please contact:</H2>
							<P>
								<strong>Domenick Propati</strong>
							</P>
							<P>
								<strong>Chief Strategy Officer</strong>
							</P>
							<P>Dom@viatouchmedia.com</P>
						</Column>
					</Row>
				</Column>
				<Img src={props.VickiFront} />
			</Row>
		);
	};

	const renderMobileView = () => {
		return (
			<Row>
				<Column
					margin="15vh 1em 5em 1em"
					justifyContent="center"
					alignItems="center"
					width="100%"
				>
					<Header>Get in touch!</Header>
					<Row wrap="wrap" alignItems="center" margin="0 0 4em 0">
						<Column width="300px" margin="0 0 5em 0">
							<H2>Sales (US / International)</H2>
							<P>
								<strong>Jim Sirignano</strong>
							</P>
							<P>
								<strong>Chief Revenue Officer</strong>
							</P>
							{/* <P>813-758-4300</P> */}
							<P>jimsirignano@viatouchmedia.com</P>
						</Column>
						<Column margin="0 2em 5em 0" width="200px">
							<H2>General Inquiries & Customer Support</H2>
							<P>+1 (855) GTVICKI</P>
						</Column>
						<Column width="300px">
							<H2>For media or partnerships, please contact:</H2>
							<P>
								<strong>Domenick Propati</strong>
							</P>
							<P>
								<strong>Chief Strategy Officer</strong>
							</P>
							<P>Dom@viatouchmedia.com</P>
						</Column>
					</Row>
				</Column>
			</Row>
		);
	};

	return <div>{isSmallScreen ? renderMobileView() : renderFullView()}</div>;
};

export default ContactDiv;
