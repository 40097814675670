import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import Button from "./Button";

const H1 = styled.span`
	font-size: 36px;
	letter-spacing: 1.23px;
	color: #88a94f;
`;

const H2 = styled.span`
	font-size: 20px;
	line-height: 26px;
	letter-spacing: 0.56px;
	color: #88a94f;
`;

const P = styled.p`
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.5px;
`;

const PWrapper = styled.div`
	align-self: flex-start;
`;

const Li = styled.li`
	font-size: 14px;
`;

const ExamplesDiv = (props) => {
	const isBigScreen = useMediaQuery({ query: "(min-width: 932px)" });
	return (
		<Column
			width={isBigScreen && "40%"}
			margin="5em 0 2em 0"
			padding={!isBigScreen && "0 1em"}
			alignItems="center"
		>
			<H1>Expand Your Brand's Reach</H1>
			<P>
				<H2>Powered by Artificial Intelligence.</H2> VICKI can answer product
				FAQs or questions about your company with AI + Natural Language
				Processing. She is the first virtual assistant for retail!
			</P>
			<P>
				<H2>Secure your products.</H2> Advanced loss prevention using computer
				vision and Intelli-shelf. With proven shrink reduction to zero on
				products such as razors, baby formula, and cosmetics. Payment is
				authorized before the door can be unlocked. Multiple payment options: Credit
				cards, NFC, thumb, and (optional) iris scan.
			</P>
			<PWrapper>
				<H2>Manage your business.</H2>
				<P>VICKI is managed via a web-based dashboard:</P>
				<ul>
					<Li>
						<P>Gain customer insights</P>
					</Li>
					<Li>
						<P>Track reports</P>
					</Li>
					<Li>
						<P>Gather valuable data & analytics</P>
					</Li>
					<Li>
						<P>Oversee products & planograms</P>
					</Li>
				</ul>
			</PWrapper>
			<P>
				<H2>Customize the experience.</H2> Flexible shelf layouts for any
				product. Customizable lighting effects inside and out. Add exterior
				graphics, branding, and media for additional engagement.
			</P>
			<Button link="true" to="features">
				Examples
			</Button>
		</Column>
	);
};

export default ExamplesDiv;
