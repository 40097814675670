import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
const ModalHeader = styled.div`
	display: ${(props) => props.headerDisplay ? props.headerDisplay : "flex"};
	justify-content: center;
	align-items: center;
	background-color: #212121;
	color: #ebebeb;
	font-size: 42px;
	line-height: 1.88;
	letter-spacing: 0.5px;
	min-height: 6vh;
	padding: 1em;

	@media only screen and (max-width: 705px) {
		text-align: center;
	}
`;

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 18px;
	padding-top: 2em;
	width: ${(props) => (props.contentWidth ? props.contentWidth : "26vw")};
	margin: 0 auto;
	align-items: center;
`;

const ModalContentHeader = styled.div`
	font-size: 32px;
	line-height: 2.38;
	letter-spacing: 0.27px;
	@media only screen and (max-width: 700px) {
		padding: 0 1em;
		text-align: center;
	}
`;

const ModalContentText = styled.div`
	font-size ${(props) => (props.contentFontSize ? props.contentFontSize : "")};
	line-height: 1.79;
	@media only screen and (max-width: 700px) {
		padding: 0 1em;
		text-align: center;
	}
	`;

Modal.setAppElement(document.getElementById("root"));

const StyledModal = (props) => {
	const modalStyles = {
		content: {
			top: "55vh",
			left: "50vw",
			right: "auto",
			bottom: "auto",
			height: props.height ? props.height : "80vh",
			width: props.width ? props.width : "35vw",
			overflow: props.overflow ? props.overflow : "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			boxShadow: props.boxShadow ? props.boxShadow : "0 2px 44px 0 rgba(0, 0, 0, 0.9)",
			background: props.background ? props.background : "white",
			border: "0",
			padding: "0",
		},
	};
	return (
		<div>
			<Modal
				isOpen={props.modalOpen}
				onRequestClose={() => props.setModalOpen(false)}
				style={modalStyles}
				closeOnEscape={true}
			>
				<ModalHeader {...props} >{props.header}</ModalHeader>

				<ModalContent {...props}>
					<ModalContentHeader>{props.modalContentHeader}</ModalContentHeader>
					<ModalContentText {...props}>{props.contentText}</ModalContentText>
					{props.children}
				</ModalContent>
			</Modal>
		</div>
	);
};

export default StyledModal;
