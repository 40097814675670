import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import H2 from "../shared/H2";
import H4 from "../shared/H4";
import P from "../shared/P";

const Img = styled.img`
	margin-left: auto;
	@media only screen and (max-width: 1224px) {
		width: auto;
		height: auto;
	}
	@media only screen and (max-width: 1153px) {
		align-self: center;
		margin: 0 auto;
	}
	@media only screen and (max-width: 725px) {
		width: 50vw;
		height: 50vw;
		padding-bottom: 1em;
	}
`;

const BioFeature = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1153px)" });
	return (
		<Row wrap="wrap" background="#f1f0ed">
			<Column
				width={!isSmallScreen && "26%"}
				justifyContent="flex-start"
				margin={!isSmallScreen && "3em 0 0 8em"}
				padding={isSmallScreen && "1em 1em"}
			>
				<H2>Secure Payments & Biometrics</H2>
				<P>
					With VICKI's strategic partnerships, you can be assured of getting the
					best, most secure payment environment possible.
				</P>
				<H4>Payment Methods</H4>
				<P>
					VICKI, in partnership with First Data, accepts credit cards, NFC
					(ApplePay) and VAS (loyalty points) via key code as payment.
				</P>
				<H4>Thumb / Iris Scan</H4>
				<P>
					With EyeLock technology embedded directly into our optical iris scan
					feature, VICKI can easily and securely authenticate your customers.
				</P>
				<P>
					After inserting a credit card, customers are given an opportunity to
					make an account by scanning their thumb for seamless future
					transactions. When using the machine again, they only have to place
					their thumb on the scanner for access and VICKI will recognize them.
				</P>
			</Column>
			<Img src={props.bioImage} />
		</Row>
	);
};

export default BioFeature;
