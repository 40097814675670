import React from 'react';
import Header from './Header';
import PrivacyPolicy from './PrivacyPolicy';
import Footer from './Footer';
import styled from 'styled-components';

const AppContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #f8f9fa;
  margin-top:130px;
`;

function TermsConditions() {
    return (
        <AppContainer>
            <Header />
            <PrivacyPolicy />
            <Footer />
        </AppContainer>
    );
}

export default TermsConditions;
