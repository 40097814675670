import React from "react";
// import { Carousel as ReactCarousel } from "react-responsive-carousel";
import Carousel from "nuka-carousel";
import "./styledcarousel.css";
const StyledCarousel = (props) => {
	return (
		// <ReactCarousel width="99vw" showStatus={false}>
		// 	{props.children}
		// </ReactCarousel>
		<Carousel
			style={{ height: "" }}
			speed={1000}
			wrapAround={true}
			defaultControlsConfig={{
				nextButtonText: "▶",
				nextButtonStyle: {
					// fontSize: "50px",
					transform: "scale(2,4)",
					paddingRight: "2em",
					background: "none",
					outline: "none",
					color: "#7b964d",
				},
				prevButtonText: "◀",
				prevButtonStyle: {
					transform: "scale(2,4)",
					paddingLeft: "2em",
					background: "none",
					outline: "none",
					color: "#7b964d",
				},
				pagingDotsClassName: "active",
				pagingDotsStyle: {
					fill: "#7b964d",
					transform: "scale(3, 3)",
					margin: "0 0.5em 1em 0.5em",
					outline: "none",
				},
			}}
		>
			{props.children}
		</Carousel>
	);
};

export default StyledCarousel;
