import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.min.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ToastContainer, Slide, toast } from "react-toastify";
import ScrollToTop from "../../utils/ScrollToTop";
import vickiService from "../../services/vicki";
import LogoBlack from "../../assets/images/viatouch_Black_Logo.png";
import LogoWhite from "../../assets/images/viatouch_logo_white.png";
import getVicki from "../../assets/images/getVICKI_logo.png";
import Navigation from "../../components/Navigation";
import NavLink from "../../components/NavLink";
import Banner from "../../components/Banner";
import Dropdown from "../../components/Dropdown";
import Footer from "../../components/Footer";
import FooterContact from "../../components/FooterContact";
import ComputerVision from "../computer-vision/ComputerVision";
import ContactUs from "../contact-us/ContactUs";
import Features from "../features/Features";
import GetVickiApp from "../get-vicki-app/GetVickiApp";
import Home from "../home/Home";
import OtherProducts from "../other-products/OtherProducts";
import Pilot from "../pilot/Pilot";
import Takeout from "../takeout/Takeout";
import UVC from "../uvc/UVC";
import VickiVerify from "../vicki-verify/VickiVerify";
import About from "../about/About";
import MediaPress from "../media-press/MediaPress";
import Customers from "../customers/Customers";
import Delivery from "../delivery/Delivery";
import pdf from "../../assets/MM_HAYES.pdf";
import TermsConditions from "../terms-conditions/TermsConditions";
import LicensingAgreements from "../licensing-agreements/LicensingAgreements";

const StyledToastContainer = styled(ToastContainer).attrs({
	// custom props
})`
	.Toastify__toast-container {
	}

	.Toastify__toast {
		text-align: center;
	}

	.Toastify__toast--dark {
		text-align: center;
		background-color: #3b403a;
		color: white;
`;

function App() {
	const [showBanner, setShowBanner] = useState(true);
	const [isHomeModalOpen, setHomeModalOpen] = useState(true);
	const [banner, setBanner] = useState("");
	const [menuOpen, setMenuOpen] = useState(false);
	const isBigScreen = useMediaQuery({ query: "(min-width: 1290px)" });
	useEffect(() => {
		vickiService.getBanner().then((data) =>
			setBanner(
				<a style={{ textDecoration: "none", color: "black", fontSize: "18px" }} href={pdf}>
					{data.banner}
				</a>
			)
		);
	}, []);

	const closeMenu = () => {
		setMenuOpen(false);
	};

	const navLinks = isBigScreen
		? [
			<Dropdown
				key="VICKI FEATURES"
				text="VICKI FEATURES"
				menuItems={[
					<NavLink padding="1em" to="features">
						Standard Features
					</NavLink>,
					<NavLink padding="1em" to="uvc">
						UV-C Sanitize Tech
					</NavLink>,
					<NavLink padding="1em" to="computer_vision">
						Computer Vision
					</NavLink>,
					<NavLink padding="1em" to="vicki_verify">
						Verify ID & Age
					</NavLink>,
					<NavLink padding="1em" to="pickup">
						Order Ahead
					</NavLink>,
				]}
			/>,
			"GET A DEMO",
			"getVICKI APP",
			// "OTHER PRODUCTS",
			<Dropdown
				key="COMPANY"
				text="COMPANY"
				menuItems={[
					<NavLink padding="1em" to="about">
						About ViaTouch
					</NavLink>,
					<NavLink padding="1em" to="customers">
						Customers
					</NavLink>,
					<NavLink padding="1em" to="media_press">
						Media & Press
					</NavLink>,
					<NavLink padding="1em" to="contact_us">
						Contact Us
					</NavLink>,
				]}
			/>,
		]
		: [
			<NavLink padding="1em" to="/" closeMenu={() => closeMenu()}>
				Home
			</NavLink>,
			<NavLink padding="1em" to="features" closeMenu={() => closeMenu()}>
				Standard Features
			</NavLink>,
			<NavLink padding="1em" to="uvc" closeMenu={() => closeMenu()}>
				UV-C Sanitize Tech
			</NavLink>,
			<NavLink
				padding="1em"
				to="computer_vision"
				closeMenu={() => closeMenu()}
			>
				Computer Vision
			</NavLink>,
			<NavLink padding="1em" to="vicki_verify" closeMenu={() => closeMenu()}>
				Verify ID & Age
			</NavLink>,
			<NavLink padding="1em" to="pickup" closeMenu={() => closeMenu()}>
				Order Ahead
			</NavLink>,
			<NavLink padding="1em" to="get_a_demo" closeMenu={() => closeMenu()}>
				Get a demo
			</NavLink>,
			<NavLink padding="1em" to="getvicki_app" closeMenu={() => closeMenu()}>
				getVICKI App
			</NavLink>,
			<NavLink padding="1em" to="about" closeMenu={() => closeMenu()}>
				About ViaTouch
			</NavLink>,
			<NavLink padding="1em" to="customers" closeMenu={() => closeMenu()}>
				Customers
			</NavLink>,
			<NavLink padding="1em" to="media_press" closeMenu={() => closeMenu()}>
				Media & Press
			</NavLink>,
			<NavLink padding="1em" to="contact_us" closeMenu={() => closeMenu()}>
				Contact Us
			</NavLink>,
		];

	return (
		<Router>
			<ScrollToTop />
			<Navigation
				logo={LogoBlack}
				alt="viatouch logo"
				navigation={navLinks}
				externalLink="https://dashboard.getvicki.com"
				externalLinkText="Dashboard Log-in"
				banner={banner}
				showBanner={showBanner}
				setShowBanner={() => setShowBanner()}
				menuOpen={menuOpen}
				setMenuOpen={setMenuOpen}
			/>
			<Switch>
				<Route path="/features">
					<Features />
				</Route>
				<Route path="/get_a_demo">
					<Pilot />
				</Route>
				<Route path="/getvicki_app">
					<GetVickiApp />
				</Route>
				<Route path="/other_products">
					<OtherProducts />
				</Route>
				<Route path="/computer_vision">
					<ComputerVision />
				</Route>
				<Route path="/contact_us">
					<ContactUs />
				</Route>
				<Route path="/uvc">
					<UVC />
				</Route>
				<Route path="/pickup">
					<Takeout />
				</Route>
				<Route path="/vicki_verify">
					<VickiVerify />
				</Route>
				<Route path="/about">
					<About />
				</Route>
				<Route path="/media_press">
					<MediaPress />
				</Route>
				<Route path="/customers">
					<Customers />
				</Route>
				<Route path="/delivery">
					<Delivery />
				</Route>
				<Route path="/terms-conditions">
					<TermsConditions />
				</Route>
				<Route path="/licensing-agreements">
					<LicensingAgreements />
				</Route>
				<Route path="/">
					<Home
						isHomeModalOpen={isHomeModalOpen}
						setHomeModalOpen={() => setHomeModalOpen()}
					/>
				</Route>
			</Switch>
			<FooterContact />
			<Footer
				getVicki={getVicki}
				getVickiAlt="Get VICKI"
				viatouchLogo={LogoWhite}
				viatouchAlt="ViaTouch Logo"
			/>
			<StyledToastContainer
				toastClassName="Toastify__toast--dark"
				enableMultiContainer
				containerId={"Success"}
				position="bottom-left"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
				transition={Slide}
			/>
			<ToastContainer
				enableMultiContainer
				containerId={"Error"}
				position="bottom-left"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
				transition={Slide}
			/>
		</Router>
	);
}

export default App;
