import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Column from "./Column";
import Row from "./Row";

const H1 = styled.span`
	font-size: 56px;
	font-weight: light;
	line-height: 1.15;
	letter-spacing: 0.57px;
	@media only screen and (max-width: 650px) {
		text-align: center;
	}
`;

const H2 = styled.span`
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0.96px;
	color: #88a94f;
	margin-top: 1em;
	margin-bottom: 2em;
`;

const P = styled.p`
	font-size: 18px;
	line-height: 1.5;
	letter-spacing: 0.71px;
	max-width: 350px;
`;

const CompanyLogo1 = styled.img`
	width: 243px;
	height: 158px;
	margin-bottom: 2em;
`;
const CompanyLogo2 = styled.img`
	width: 458px;
	height: 138px;

	@media only screen and (max-width: 1550px) {
		width: 55vw;
		height: 10vh;
	}
`;

const AppImg = styled.img`
	padding-left: 5em;
	padding-bottom: 2.5em;
	width: 87px;
	height: 175px;
	align-self: flex-end;

	@media only screen and (max-width: 1550px) {
		padding-bottom: 2em;
		padding-left: 0;
	}

	@media only screen and (max-width: 530px) {
		padding-bottom: 1em;
		width: 67px;
		height: 135px;
	}
`;

const Img = styled.img`
	width: 540px;
	height: 700px;
	@media only screen and (max-width: 1550px) {
		width: 35vw;
		height: 45vw;
	}
`;

const TakeoutDiv = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1552px)" });

	const renderFullView = () => {
		return (
			<Row justifyContent="center" width="auto" padding="0 0 0 15em">
				<Column width="600px" alignItems="center" justifyContent="center">
					<H1>
						Order Pickup with <img src={props.vickiText} />
					</H1>
					<P>
						VICKI can integrate into your current POS system or use the VICKI
						dashboard to customize your menu items. With VICKI's Order for
						PickUp functionality customers can order menu items and pick them up
						from a specific VICKI kiosk.
					</P>
					<H2>Current Customers Include:</H2>
					<CompanyLogo1 src={props.companyLogo1} />
					{/* <CompanyLogo2 src={props.companyLogo2} /> */}
				</Column>
				<Row width="auto">
					<AppImg src={props.takeoutApp} />
					<Img src={props.takeoutMachine} />
				</Row>
			</Row>
		);
	};

	const renderMobileView = () => {
		return (
			<Row justifyContent="center" width="auto" padding="1em 1em">
				<Column alignItems="center" justifyContent="center">
					<H1>
						Order Pickup with <img src={props.vickiText} />
					</H1>
					<P>
						VICKI can integrate into your current POS system or use the VICKI
						dashboard to customize your menu items. With VICKI's Order for
						PickUp functionality customers can order menu items and pick them up
						from a specific VICKI kiosk.
					</P>
					<Row width="auto">
						<AppImg src={props.takeoutApp} />
						<Img src={props.takeoutMachine} />
					</Row>
					<H2>Current Customers Include:</H2>
					<CompanyLogo1 src={props.companyLogo1} />
					{/* <CompanyLogo2 src={props.companyLogo2} /> */}
				</Column>
			</Row>
		);
	};

	return <div>{isSmallScreen ? renderMobileView() : renderFullView()}</div>;
};

export default TakeoutDiv;
