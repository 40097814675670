import React from "react";
import VickiText from "../../assets/images/vicki_text.png";
import checkmark from "../../assets/images/checkmark.png";
import Machine from "../../assets/images/uvc_example.png";
import Wavelength from "../../assets/images/wavelength.png";
import AIQuestion from "../../assets/images/AI_Question.png";
import SanitizeClean from "../../assets/images/Sanitize_Clean.png";
import TouchFree from "../../assets/images/TouchFree.png";
import Customizable from "../../assets/images/Customizable.png";
import OrderDeliver from "../../assets/images/Order_Deliver.png";
import QRCode from "../../assets/images/QR_Code.png";
import Column from "../../components/Column";
import Row from "../../components/Row";
import UVCDiv from "../../components/UVCDiv";
import UVCExample from "../../components/UVCExample";
import UVCFeatures from "../../components/UVCFeatures";

const UVC = (props) => {
	const images = [
		{
			"AI Virtual Attendant": AIQuestion,
			"UV-C Self-Sanitizing Tech": SanitizeClean,
			"QR & Contactless Payments": QRCode,
		},
		{
			"Touch Free(ly)": TouchFree,
			"Customizable Layout": Customizable,
			"Pick-Up Capabilities": OrderDeliver,
		},
	];

	return (
		<Column padding="15vh 0 0" alignItems="center">
			<UVCDiv vickiText={VickiText} checkmark={checkmark} machine={Machine} />
			{/* <img height="835px" width="711px" src={Machine} /> */}
			<UVCExample image={Wavelength} />
			<UVCFeatures firstRowImages={images[0]} secondRowImages={images[1]} />
		</Column>
	);
};

export default UVC;
