import styled from "styled-components";

const SubHeader = styled.span`
	font-size: 24px;
	font-weight: 600;
	letter-spacing: 0.45px;
	margin-bottom: 0.5em;
	color: #88a94f;
`;

export default SubHeader;
