import styled from "styled-components";

const P = styled.p`
	font-size: 15px;
	line-height: 1.73;
	letter-spacing: 0.54px;
	margin: ${(props) => props.margin || "0 0 2em 0"};
	color: ${(props) => props.color || "black"};
`;

export default P;
