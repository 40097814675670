import React from "react";
import Column from "../../components/Column";
import MediaPressDiv from "../../components/MediaPressDiv";

const MediaPress = (props) => {
	return (
		<Column padding="15vh 0 0 0" alignItems="center">
			<MediaPressDiv />
		</Column>
	);
};

export default MediaPress;
