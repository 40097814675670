import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
	margin: ${(props) => props.margin};
	padding: ${(props) => props.padding};
`;

const Img = styled.img`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
`;

const ViaTouchLogo = (props) => {
	return (
		<Wrapper {...props}>
			<Link to="/">
				<Img {...props} src={props.logo} alt={props.alt} />
			</Link>
		</Wrapper>
	);
};

export default ViaTouchLogo;
