import React from "react";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";

const Header = styled.h2`
	color: #87a630;
	font-size: 2em;
	font-weight: 300;
	margin: 0;
	padding: 1em 2em;
`;

const Img = styled.img`
	width: 100%;
	height: 30%;
`;

const HomeBanner = (props) => {
	return (
		<Row
			wrap="wrap"
			margin="5em 0 0 0"
			background="#212121"
			alignItems="flex-start"
		>
			<Img src={props.banner} />
			{/* <Column>
				<Header>Retail Technology...</Header>
			</Column>
			<Column>
				<Header>Self-Checkout</Header>
				<Header>AI</Header>
				<Header>UV-C</Header>
				<Header>Inventory Management</Header>
			</Column> */}
		</Row>
	);
};

export default HomeBanner;
