import React from 'react';
import styled from 'styled-components';
import SubSection from './SubSection';

const PolicyContainer = styled.section`
  margin: 20px 0;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
  color: #5c9820;
  text-align: center;
`;

const Small = styled.h3`
    color: #333;
    font-size: 16px;
    text-align: center;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #333;
`;

const PrivacyPolicy = () => (
    <PolicyContainer>
        <Small>SCHEDULE D</Small>
        <Title>VIATOUCH PRIVACY POLICY</Title>
        <Paragraph>
            Effective: June, 2018
        </Paragraph>

        <SubSection>
            <Paragraph>
                ViaTouch Media, Inc. (“ViaTouch”) is committed to protecting your privacy and we treat your
                privacy seriously. We process information about you in accordance with our Privacy Policy
                (“Privacy Policy”). By using ViaTouch’s software (the “Services”), you agree to the way in which
                we process and deal with your personal information, and the personal information you collect
                through any third party or end user (“Users”) (collectively, the “Personal Information”).
            </Paragraph>
            <Paragraph>
                It is your responsibility to read this Privacy Policy. Please take a moment to familiarize yourself
                with our privacy practices and let us know if you have any questions. If you do not accept this
                Privacy Policy, you must not use the Services.
            </Paragraph>
        </SubSection>

        <SubSection title="Collection and Use of Personal Information">
            <Paragraph>
                Personal Information is data that can be used to identify or contact a single person. Personal
                Information includes your data or third-party data collected by you. We may collect a variety of
                information including, but not limited to, your name, email address, mailing address, phone
                number, preferences, date of birth, usernames, fingerprint scans, and payment information.
            </Paragraph>
            <Paragraph>How we use your personal information:</Paragraph>

            <ul>
                <li>to provide support and respond to inquiries;</li>
                <li>to facilitate communicate with other users of the Services;</li>
                <li>to keep you posted on ViaTouch’s latest product announcements, service announcements,
                    promotions, or upcoming events;</li>
                <li>to provide updates and notices regarding our Privacy Policy;</li>
                <li>to administer contests or similar promotions;</li>
                <li>for internal purposes including, but not limited to, auditing, data analysis, and research to
                    improve our product offering, service offering, and customer communications;</li>
                <li>to comply with our legal obligations, resolve any disputes that we may have with any of
                    our users, and enforce our agreements with third parties;</li>
                <li>in any other way we may describe when you provide the information; or</li>
                <li>to fulfill any other purpose for which you provide it or for any other purpose with your
                    consent. </li>
            </ul>
            <Paragraph>When using the Service, you are responsible for the information that you disclose, including the
                information of third-parties. Please exercise discretion and caution when disclosing any
                information. You understand that by using the Service, any information that you provide is given
                freely and voluntarily. Although we have reasonable security measures in place, we cannot and
                do not assure the confidentiality of any information that you disclose in connection with the
                Service.</Paragraph>
        </SubSection>
        <SubSection title='Collection and Use of Non-Personal Information'>
            <Paragraph>We also collect non-personal information which is data in a form that does not, on its own, allow
                direct association with any specific individual. Non-personal information may be collected, used,
                transferred, and disclosed for any purpose.</Paragraph>
            <Paragraph>We may collect non-personal information such as IP addresses, browser type, operating system,
                search history, referral source, exit pages, browsing activity, dates and times of visit, other site
                usage data, geographical location, screen resolution, etc. This data may be compiled and analyzed
                on both an individual and aggregated basis.</Paragraph>
            <Paragraph>How we use non-personal information:</Paragraph>
            <ul>
                <li>We may use non-personal information to better understand customer behavior, provide
                    more useful information to our customers, understand which parts of the Service are most
                    interesting, and improve our products, services, and advertising.</li>
                <li>We may use non-personal information to send targeted email marketing messages or
                    display targeted advertising.</li>
                <li>We may also use non-personal information to diagnose technical issues with our software
                    and server, administer services, gather demographics, and improve the Service.</li>
            </ul>
        </SubSection>
        <SubSection title='Cookies and Other Technology'>
            <Paragraph>The Service, our online services, email messages, and advertisements may use cookies and other
                tracking technologies such as pixel tags and web beacons. A cookie is a small piece of text sent to
                your browser. It remembers information about your visit, like your preferred language and other
                settings. Some examples of how we use cookies are to track your local preferences, to access your
                information when you sign in to your account so that we can provide you with personalized
                content, to display the most relevant advertisements, and to help us analyze traffic and use of the
                Service. Cookies do not store your personal information. </Paragraph>
            <Paragraph>We may also use pixel tags, web beacons, clear gif technology, action tags or other similar tracking
                technology. These features allow us to monitor the use of the Service. Examples of information
                that may be collected include the IP address of the computer that downloaded the page, the browser
                type, information regarding any cookies previously placed on the computer by that server. In
                addition, we may collect and aggregate the time spent on the Service, viewer actions such as
                scrolling or clicking, and user action on the Service including page views. We may also use
                variants of this technology provided by a third party to collect information about your visit in
                conjunction with our cookies to provide offers and information that may be of interest to you.</Paragraph>
            <Paragraph>The use of cookies by our partners, affiliates, or service providers is not covered by our privacy
                policy but rather by their own privacy policy. We do not have access or control over these cookies.</Paragraph>
            <Paragraph>Cookies are permanent files that remain on your computer’s non-volatile storage until you delete
                them manually through your browser or operating system. </Paragraph>
        </SubSection>
        <SubSection title='Advertising'>
            <Paragraph>We may use other companies to provide advertisements when you use the Service. These
                companies may collect and use non-personal information such as click stream information,
                browser type, time and date, etc. in order to provide advertisements about goods and services.
                These companies typically use a cookie, web beacon, or pixel tags to collect this information which
                is subject to their own privacy policies.</Paragraph>
        </SubSection>
        <SubSection title='Information Sharing and Disclosure'>
            <Paragraph>We may disclose personal information that we collect or that you provide as described in this
                Privacy Policy:</Paragraph>
            <ul>
                <li>to our subsidiaries and affiliates;</li>
                <li>to contractors, service providers, and other third parties we use to support our business;</li>
                <li>to respond to subpoenas, court orders, legal process, or to establish or exercise our legal
                    rights or defend against legal claims;</li>
                <li>to investigate, prevent, or take action regarding illegal activities, suspected fraud,
                    situations involving potential threats to physical safety of any person, violation of
                    ViaTouch’s Terms of Service, or as otherwise required by law; and/or</li>
                <li>to a buyer or other successor in the event of a merger, divestiture, restructuring,
                    reorganization, dissolution, or other sale or transfer of some or all of ViaTouch’s assets,
                    whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding,
                    in which your personal information is among the assets transferred. </li>
            </ul>
        </SubSection>
        <SubSection title='Information You Collect'>
            <Paragraph>You shall not modify, create derivative works of, or attempt to decipher, decompile, disassemble
                or reverse engineer the Service. You shall not, and shall not permit others, in using the Service to:
                (i) publish, ship, distribute or disseminate material or information that encourages conduct that
                could constitute a criminal offense or give rise to civil liability; (ii) engage in any conduct that
                could constitute a criminal offense or give rise to civil liability for Supplier; (iii) misrepresent or
                in any other way falsely identify your identity or affiliation, including through impersonation or
                altering any technical information in communications using the Service; (iv) transmit or upload
                any material through the Service containing viruses, trojan horses, worms, time bombs, cancelbots,
                or any other programs with the intent or effect of damaging, destroying, disrupting or otherwise
                impairing ViaTouch’s, or any other person’s or entity’s, network, computer system, or other
                equipment; (v) interfere with or disrupt the Service, networks, or servers connected to ViaTouch
                systems or violate the regulations, policies or procedures of such networks or servers, including
                unlawful or unauthorized altering of any of the information submitted through the Service; (vi)
                attempt to gain unauthorized access to the Service, other ViaTouch customers’ computer systems,
                or networks using the Service through any means; or (vii) interfere with another party’s use of the
                Service. ViaTouch has no obligation to monitor your use of the Service. However, ViaTouch
                reserves the right (but has no obligation) at all times to monitor, review, retain and disclose any
                information as necessary to satisfy or cooperate with any applicable law, regulation, legal process
                or governmental request. You shall be responsible for all acts and omissions of its Users as if such
                acts or omissions were its own</Paragraph>
            <Paragraph>You shall be responsible for the personal information and for ensuring that the personal
                information does not infringe any third-party rights or violate any legislation. You warrant that
                you have the necessary rights to transfer and provide the personal information to the Service and
                that ViaTouch and its subcontractors may process the personal information and any personal data
                included in the personal information, for the purposes of the Agreement. </Paragraph>
            <Paragraph>You may disclose the personal information to your subcontractors, and other necessary third
                parties for the purpose of the Agreement, provided that, your subcontractors, and other necessary
                third parties has agreed on a confidentiality provision no less restrictive as that herein with the
                subcontractors. </Paragraph>
        </SubSection>
        <SubSection title='Social Media Features and Widgets'>
            <Paragraph>The Service may include social media features, widgets or integrations such as Facebook, Twitter,
                LinkedIn, Yahoo, Microsoft, Google, or other relevant social media platforms. These features and
                widgets will be clearly marked with relevant logo or phrasing (for example, the Facebook button
                or Facebook like button). If you choose to click one of these features or widgets, your browser
                may connect directly to their server to display the content. These features may collect your IP
                address, which page you are visiting, and may use cookies to enable features to work properly.
                These features and widgets may either be hosted on our servers or third-party servers. Your
                interactions with any third-party features or widgets are governed by the respective privacy policy
                of the company providing it.</Paragraph>
        </SubSection>
        <SubSection title='Control of Personal Information'>
            <Paragraph>Registered users of the Service can modify or delete the personal information that they provided
                upon registration including their contact preferences. We may, from time to time, send you
                promotional emails regarding our product and service offerings that may be of interest to you. You
                may opt out from receiving promotional emails by clicking the “Unsubscribe” button at the bottom
                of any promotional email received. Please note, that even if you opt out of promotional emails
                you may still receive messages pertaining to services offered including, but not limited to,
                communications related to fulfilling a transaction, service announcements, notices regarding
                ViaTouch’s Terms of Service, Privacy Policy, or administrative messages. </Paragraph>
            <Paragraph>You may delete your account at any time. Please note that even after removing information from
                your profile or deleting your account, copies of the deleted information may remain viewable
                elsewhere to the extent that it has been shared with others or it was otherwise distributed pursuant
                to these terms. We will retain your personal information for as long as your account is active or as
                needed to provide you with requested services. We will also retain and use your personal
                information as necessary to comply with our legal obligations, resolve disputes, or enforce our
                agreements.</Paragraph>
        </SubSection>
        <SubSection title='Your California Privacy Rights'>
            <Paragraph>If you are a California resident, you have the right to request information regarding the manner in
                which we share certain categories of your personal information with third parties, for the third
                parties’ direct marketing purposes. You have the right to submit a request to us at the designated
                address and receive the following information: </Paragraph>
            <ul>
                <li>The categories of information we disclosed to third parties for the third parties direct
                    marketing purposes during the preceding calendar year. </li>
                <li>The names and addresses of third parties that received such information, and if the nature
                    of a third party's business cannot be reasonably determined from the third party’s name,
                    examples of the products or services marketed.</li>
            </ul>
            <Paragraph>To request a copy of this information in a standardized format (note – the information will not be
                individually tailored to you), please send your request to us at <a href="mailto:jimsirignano@viatouchmedia.com" style={{ marginLeft: 3 }}>jimsirignano@viatouchmedia.com</a>.</Paragraph>
        </SubSection>
        <SubSection title='International Users'>
            <Paragraph>Please note that the Service is directed towards users who reside in the United States. By using
                the Service, you consent to the collection, storage, processing, and transfer of your information in
                and to the United States, or other countries and territories, pursuant to the laws of the United States.
                While some of these countries may not offer the same level of privacy protection as your own, we
                commit to uphold the privacy protections as explained in this Privacy Policy. </Paragraph>
        </SubSection>
        <SubSection title='Children’s Privacy'>
            <Paragraph>ViaTouch does not knowingly collect, use, or disclose personal information from children under
                the age of 13, except as permitted by the Children’s Online Privacy Protection Act (“COPPA”).
                We encourage parents to be involved in the online activities of their children to ensure that no
                information is collected from a child without verifiable parental permission. If we are made aware
                that we have collected personal information from a child under 13 years old in a manner that is
                inconsistent with COPPA, we will delete this information as soon as possible. </Paragraph>
        </SubSection>
        <SubSection title='Confidentiality and Security of Personal Information'>
            <Paragraph>ViaTouch is committed to protecting your privacy and we treat your privacy very seriously. We
                limit access to personal information about our customers to our employees and agents on a need
                to know basis. Personal information is only provided to personnel who need to know the
                information to provide services requested. We follow generally accepted standards and best
                practices to protect the security of personal information submitted to us from unauthorized access
                including encryption, secure passwords, physical security, etc. These protections, however, are not
                a replacement for the security measures in your control. No method of electronic data transmission
                or electronic data storage is one hundred percent secure and we cannot guarantee the absolute
                security of your information. It is important that you protect against unauthorized access to your
                password and to your computer or mobile device.</Paragraph>
            <Paragraph>ViaTouch has the right to use the personal information only for the purposes of performing its
                obligations and exercising its rights under this Agreement. Notwithstanding the foregoing, the
                ViaTouch shall have a perpetual, irrevocable, transferable and sub licensable right to use the
                personal information and any output data of the personal information processed by the Service in
                anonymous form for the purposes of statistical analysis and development of the Service, and other
                products and services. Such anonymous data cannot be connected to you or third-party users.</Paragraph>
            <Paragraph>ViaTouch has the right to remove personal information, which it suspects to be contrary to law or
                regulation, or which in ViaTouch’s opinion may harm the Service or third-party users, or if it
                receives a notification of a suspected infringement. In such event, ViaTouch shall promptly notify
                you prior to such removal. </Paragraph>
        </SubSection>
        <SubSection title='Third Parties'>
            <Paragraph>This Privacy Policy solely governs the use and disclosure of information that ViaTouch collects
                from you or through the Service and does not apply to the practices of any third-party. Please be
                aware that when you click through to a third-party website or application or use a third-party
                feature or widget that our Privacy Policy no longer applies and any information you provide to
                them is subject to their privacy policy. </Paragraph>
        </SubSection>
        <SubSection title='Changes to Privacy Policy'>
            <Paragraph>We may update this policy at any time by posting the updated terms on the Service, by updating
                the link on the Service page, and changing the “last updated” date. If we make a material change
                to this policy pertaining to the use of your personal information, we will update our users by
                placing a prominent notice on the Service, or by sending an email notice. Use of the Service after
                an update to the Privacy Policy constitutes your acceptance of the updated policy.</Paragraph>
        </SubSection>
        <SubSection title='Contact Us'>
            If you have any questions about this Privacy Policy, please e-mail us at
            <a href="mailto:jimsirignano@viatouchmedia.com" style={{ marginLeft: 3 }}>jimsirignano@viatouchmedia.com</a>.
        </SubSection>

        {/* Add more sections as needed */}
    </PolicyContainer>
);

export default PrivacyPolicy;
