import styled from "styled-components";

const H4 = styled.span`
	font-size: 20px;
	font-weight: 600;
	color: ${(props) => props.color || "#88a94f"};
	margin-bottom: ${(props) => props.marginBottom || "0.5em"};
`;

export default H4;
