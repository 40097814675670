import styled from "styled-components";

const Header = styled.span`
	font-size: 80px;
	font-weight: 300;
	align-self: center;
	margin-bottom: 1em;
`;

export default Header;
