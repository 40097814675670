import styled from "styled-components";

const Link = styled.a`
  text-decoration: none;
  background-color: white;
  width: 196px;
  height: 34px;
  border-radius 4px;
  text-align: center;
  line-height: 34px;
  font-size: 20.4px;
  letter-spacing: 1.28px;
  color: #7b964d;
`;

export default Link;
