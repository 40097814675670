import React from "react";
import Column from "./Column";
import P from "../shared/P";
import Header from "../shared/Header";
import SubHeader from "../shared/SubHeader";

const AboutViaTouch = (props) => {
	return (
		<Column width="90%" alignItems="flex-start">
			<Header>ViaTouch Media Inc.</Header>
			<SubHeader>About The Company</SubHeader>
			<P>
				ViaTouch Media is a software technology company focused on delivering
				solutions to disrupt the retail shopping industry. At the core of
				ViaTouch Media is the VICKI software platform.
			</P>
			<P>
				VICKI enables retailers to realize new revenue streams and reduce
				operating expenses while leveraging rich data analytics and advanced
				hardware peripherals.
			</P>
			<SubHeader>About The Team</SubHeader>
			<P>
				A group of entrepreneurs, designers, and engineers with over 50 years of
				experience in international marketing, innovative media, food service,
				and cutting edge technology.
			</P>
		</Column>
	);
};

export default AboutViaTouch;
