import React from "react";
import styled from "styled-components";
import Column from "./Column";

const Img = styled.img`
	width: 145px;
	height: 291px;
	margin-bottom: 2em;
`;

const ImgContainer = styled.div`
	width: 60%;
	@media only screen and (max-width: 780px) {
		display: flex;
		justify-content: center;
		width: 100%;
	}
`;

const Header = styled.span`
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0.71px;
	color: #88a94f;
	@media only screen and (max-width: 780px) {
		align-self: center;
	}
`;

const Caption = styled.span`
	font-size: 18px;
	line-height: 1.44;
	letter-spacing: 0.64px;
	// width: 40%;
	@media only screen and (max-width: 780px) {
		align-self: center;
	}
`;

const GetVickiExample = (props) => {
	return (
		<Column
			margin="0 2em 5em 2em"
			justifyContent="center"
			alignItems={props.align || "flex-start"}
			width={props.width || "23%"}
		>
			<ImgContainer>
				<Img src={props.iphone} />
			</ImgContainer>
			<Column width="60%">
				<Header>{props.header}</Header>
				<Caption>{props.caption}</Caption>
			</Column>
		</Column>
	);
};

export default GetVickiExample;
