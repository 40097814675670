import React from "react";
import Row from "./Row";
import Employee from "./Employee";

const EmployeeDiv = (props) => {
	return (
		<Row margin="0 0 3em 0" wrap="wrap" width="40%" justifyContent="center">
			{props.employees.map((employee) => (
				<Employee
					name={employee[0]}
					title={employee[1]}
					employeeImage={employee[2]}
				/>
			))}
		</Row>
	);
};

export default EmployeeDiv;
