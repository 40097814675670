import threemachine from "../../assets/images/threemachine.png";
import cosmetics from "../../assets/Cosmetics_Case_Study.pdf";
import electronics from "../../assets/Electronics_Case_Study.pdf";
import fitness from "../../assets/National_Fitness_Club_Case_Study.pdf";
import order from "../../assets/Order_Pick-Up_Case_Study.pdf";

export const caseStudies = [
	[
		"Cosmetics Case Study",
		"Partnered with a large cosmetic company to help them extend their brand beyond traditional brick and mortar and closer to their customers in non-traditional locations like higher education   ",
		threemachine,
		cosmetics,
	],
	[
		"Electronics Case Study",
		"Partnered with a large convenience store chain to create a net new non-cannibalized revenue stream with higher priced electronics that would are normally not carried in the store shelves for loss prevention reasons ",
		threemachine,
		electronics,
	],
	[
		"National Fitness Case Study",
		"Partnered with a national fitness company to eliminate employee check out of pre and post workout beverages and provide members with a frictionless self checkout solution thus freeing up employees to focus on core member services",
		threemachine,
		fitness,
	],
	[
		"Order Pick-Up Case Study",
		"Partnered with a large financial institution and delivery application to offer a sandwich ordering and pick-up service through the company food service application",
		threemachine,
		order,
	],
];
