import React from "react";
import styled from "styled-components";

const Store = styled.a`
	text-decoration: none;
	width: 122px;
	height: 36px;
	margin: ${(props) => props.margin || "0 1em 0 1em"};
`;

const StoreButton = (props) => {
	return (
		<Store {...props} href={props.storeLink}>
			<img src={props.store} />
		</Store>
	);
};

export default StoreButton;
