import React from "react";
import styled from "styled-components";

import Row from "./Row";

const BannerContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	background-color: #82c141;
	min-height: 52px;
	width: 100%;
	padding: auto;
`;
const TextContainer = styled.span`
	margin-left: auto;
	font-size: 20px;
`;

const Close = styled.span`
	margin-left: auto;
	margin-right: 2em;
	font-size: 20px;
	font-weight: bold;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	align-self: center;
`;

const Banner = (props) => {
	return (
		<BannerContainer style={{ display: props.showBanner ? "" : "none" }}>
			<Row justifyContent="center">
				<TextContainer>{props.children}</TextContainer>
				<Close onClick={() => props.setShowBanner(false)}>X</Close>
			</Row>
		</BannerContainer>
	);
};

export default Banner;
