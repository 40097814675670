import placeholder from "../../assets/images/placeholder.png";
import fiserv from "../../assets/images/fiserv.png";
import fki from "../../assets/images/fki.png";
import navajo from "../../assets/images/navajo.png";
import ospraie from "../../assets/images/ospraie.png";
import sprint from "../../assets/images/Sprint.png";
import voxx from "../../assets/images/voxx.png";

export const employees = [
	["Tom Murn", "CEO", placeholder],
	["Jim Sirignano", "CRO/COO", placeholder],
	["Domenick Propati", "CSO/CDO", placeholder],
	["Milan Alvarado", "CTO", placeholder],
	["Preetham Rajkumar", "Computer Vision", placeholder],
	["Eric Gonzalez", "Software", placeholder],
	["Joe Churchwell", "Mechatronics", placeholder],
	["Abinandan Mamidi", "Software", placeholder],
	["Joey Wang", "Computer Vision", placeholder],
	["Jeremey Lee", "Software", placeholder],
	["Wes Buehmaier", "Mechatronics", placeholder],
	["Frank Deik", "Logistics", placeholder],
];

export const partners = [fiserv, fki, ospraie, sprint, voxx, navajo];
