import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import H2 from "../shared/H2";
import H4 from "../shared/H4";
import P from "../shared/P";

const Img = styled.img`
	padding-top: 1em;
	margin-left: auto;
	width: 1107px;
	height: 612px;
	@media only screen and (max-width: 1690px) {
		width: 60%;
		height: 60%;
		align-self: center;
	}
	@media only screen and (max-width: 1180px) {
		margin-left: 0;
	}
	@media only screen and (max-width: 730px) {
		width: 90vw;
		height: 30vh;
	}
`;

const ReportFeature = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1180px)" });
	return (
		<Row wrap="wrap" background="#f1f0ed" justifyContent={isSmallScreen && "center"} >
			<Column
				width={!isSmallScreen && "25%"}
				margin={!isSmallScreen && "3em 2em 0 8em"}
				padding={isSmallScreen && "1em 1em"}

			>
				<H2>Reporting and Analytics</H2>
				<P>
					Get a better understanding of your customer and their purchasing
					habits so that you can tailor your merchandising strategy to give your
					customers the products they love most.
				</P>
				<H4>Reporting</H4>
				<P>
					Get detailed reports on customers, products, sales, and services in
					order to optimize business practices.
				</P>
				<H4>Data Analytics</H4>
				<P>
					Learn and track customer preferences and habits to better serve new
					targeted products, services, promotions, and advertisements
				</P>
				<H4>Breadcrumbs</H4>
				<P>
					See a detail breakdown of every customer session including: products
					that were picked up or put back, media consumed, AI questions asked,
					and answers provided in addition to promotions.
				</P>
			</Column>
			<Img src={props.reportImage} />
		</Row>
	);
};

export default ReportFeature;
