import React from 'react';
import styled from 'styled-components';

const SubSectionContainer = styled.div`
  margin-bottom: 20px;
`;

const SubSectionTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
  color: #5c9820;
`;

const SubSection = ({ title, children }) => (
    <SubSectionContainer>
        <SubSectionTitle>{title}</SubSectionTitle>
        {children}
    </SubSectionContainer>
);

export default SubSection;
