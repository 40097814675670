import React from "react";
import styled from "styled-components";

const WideImage = styled.img`
	width: 100%;
	margin: ${(props) => props.margin};
`;

const WideImg = (props) => {
	return <WideImage src={props.src} alt={props.alt} {...props} />;
};

export default WideImg;
