import React from "react";
import { employees, partners } from "./data";
import Column from "../../components/Column";
import AboutViaTouch from "../../components/AboutViaTouch";
import EmployeeDiv from "../../components/EmployeeDiv";
import Quote from "../../components/Quote";
import Partners from "../../components/Partners";

const About = (props) => {
	return (
		<Column padding="15vh 0 0 0" alignItems="center">
			<AboutViaTouch />
			<EmployeeDiv employees={employees} />
			<Quote />
			<Partners partners={partners} />
		</Column>
	);
};

export default About;
