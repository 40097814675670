import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  text-align: center;
  padding: 20px;
  background-color: #343a40;
  color: white;
  font-size: 24px;
`;

const Header = () => (
    <HeaderContainer>
        Terms of Service and Privacy Policy
    </HeaderContainer>
);

export default Header;
