import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import H2 from "../shared/H2";
import H4 from "../shared/H4";
import P from "../shared/P";

const Img = styled.img`
	padding-top: 3em;
	padding-bottom: 3em;
	margin-left: auto;
	@media only screen and (max-width: 750px) {
		width: 80vw;
		height: 50vh;
	}
	@media only screen and (max-width: 400px) {
		width: 100vw;
		height: 50vh;
	}
`;

const DashboardFeature = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 750px)" });
	return (
		<Row wrap="wrap" background="#f1f0ed">
			<Column
				width={!isMobile && "24%"}
				margin={!isMobile && "3em 2em 0 8em"}
				padding={isMobile && "1em 1em"}
			>
				<H2>Dashboard Management</H2>
				<H4>VICKI Setup</H4>
				<P>
					Manage machine locations and areas. Assign them to groups. Check
					machine diagnostics, see alerts, see each planogram, media, and items
					assigned to each machine.
				</P>
				<H4>Inventory Controls</H4>
				<P>
					A group of entrepreneurs, designers, and engineers with over 50 years
					of experience in international marketing, innovative media, food
					service, and cutting edge technology
				</P>
				<H4>Planogram Management</H4>
				<P>
					Position your products for maximum exposure. Manage shelving, add
					media, and change pricing.
				</P>
				<H4>Reports Management</H4>
				<P>
					Get reporting on finances, events, and accounts as well as seeing when
					and how products move best.
				</P>
				<H4>Customization Controls</H4>
				<P>Configure your VICKI with custom lighting effects, media, and AI.</P>
			</Column>
			<Img src={props.dashboardImage} />
		</Row>
	);
};

export default DashboardFeature;
