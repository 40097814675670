import React from "react";
import styled from "styled-components";

const Col = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: ${(props) =>
		props.justifyContent ? props.justifyContent : "center"};
	align-items: ${(props) => props.alignItems};
	align-self: ${(props) => props.alignSelf};
	padding: ${(props) => props.padding};
	margin: ${(props) => props.margin};
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	background: ${(props) => props.background || ""};
`;

const Column = (props) => {
	return <Col {...props}>{props.children}</Col>;
};

export default Column;
