import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";
import Button from "./Button";
const ButtonContainer = styled.div``;

const Header = styled.span`
	font-size: 28px;
	line-height: 1.46;
	letter-spacing: 1.8px;
	color: #aad264;
	margin-bottom: 0.5em;
`;

const P = styled.p`
	font-size: 14px;
	line-height: 1.64;
	letter-spacing: 1.16px;
	color: white;
	margin-bottom: 1em;
`;

const GetVickiMore = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 780px)" });
	return (
		<Row width="100%" background="#212121" justifyContent="center">
			<Column width={isSmallScreen ? "90%" : "34%"} margin="2em 1em 5em 0">
				<Header>Drive Localized App Downloads & Acquire Users</Header>
				<P>
					<strong>VICKI Rewards App Features Your Brand.</strong> Your app and
					brand will be featured on the home page of the VICKI
					Rewards App for all users to see.
				</P>
				<P>
					<strong>VICKI Rewards Members Download Your App.</strong>
					Customers select your app and are redirected to their phones app
					store. VICKI will confirm the app has been installed before awarding
					the user with the designated VICKI Rewards Points.
				</P>
				<P>
					<strong>VICKI Rewards Members Earn Rewards Points.</strong> Users are
					inclined to download and use your app because they will earn VICKI
					Rewards Points. POints convert to money that can be used at any VICKI
					Kiosk.
				</P>
			</Column>
			<Button
				style={{ display: "none" }}
				alignSelf="center"
				margin="0 0 0 5em"
				color="white"
			>
				Learn More
			</Button>
		</Row>
	);
};

export default GetVickiMore;
