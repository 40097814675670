import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import H2 from "../shared/H2";
import H4 from "../shared/H4";
import P from "../shared/P";

const PackagesFeature = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 850px)" });

	const renderFullView = () => {
		return (
			<Column justifyContent="center" alignItems="center" margin="0 0 3em 0">
				<Row width="100%" height="500px" justifyContent="space-evenly">
					<Column width="24%">
						<H2>Packages</H2>
						<H4>Advanced Biometrics with Eyelock©</H4>
						<P>
							<strong>Includes: </strong>Integrated Eyelock iris scanner, VICKI
							iris software, intuitive and easy to use on-boarding process and
							customer experience.
						</P>
						<H4>Age Verification</H4>
						<P>
							<strong>Includes: </strong>Advanced biometrics package and access to the VICKI Verified ID software.
						</P>
						<H4>Advanced Loss Prevention</H4>
						<P>
							<strong>Includes: </strong>Computer vision camera system. CV
							software, VICKI intelligence upgrade, product training software.
						</P>
					</Column>
					<Column width="24%">
						<H4>Additional Trays</H4>
						<P margin="0 0 0 0">
							<strong>1X </strong>(package of 2 trays)
						</P>
						<P margin="0 0 0 0">
							<strong>2X </strong>(package of 2 trays)
						</P>
						<P>
							<strong>7X </strong>(full shelf)
						</P>
						<H4>Custom Paint and Branding</H4>
						<P>
							<strong>Includes: </strong>Hydro-dipped or custom-painted sides,
							front and back of machine. Custom decals on the back and sides can
							also be applied for an additional cost.
						</P>
						<P margin="0 0 0 0">
							<strong>
								<em>
									Customers can also partner with us on any custom need such as
									a direct integration or order-ahead app.
								</em>
							</strong>
						</P>
					</Column>
				</Row>
			</Column>
		);
	};

	const renderMobileView = () => {
		return (
			<Column justifyContent="center" alignItems="center" padding="1em 1em">
				<Row wrap="wrap" width="100%" height="500px">
					<Column>
						<H2>Packages</H2>
						<H4>Advanced Biometrics with Eyelock©</H4>
						<P>
							<strong>Includes: </strong>Integrated Eyelock iris scanner, VICKI
							iris software, intuitive and easy to use on-boarding process and
							customer experience.
						</P>
					</Column>
					<Column>
						<H4>Age Verification</H4>
						<P>
							<strong>Includes: </strong>Advanced biometrics package and access to the VICKI Verified ID software.
						</P>
					</Column>
					<Column>
						<H4>Advanced Loss Prevention</H4>
						<P>
							<strong>Includes: </strong>Computer vision camera system. CV
							software, VICKI intelligence upgrade, product training software.
						</P>
					</Column>
					<Column>
						<H4>Additional Trays</H4>
						<P margin="0 0 0 0">
							<strong>1X </strong>(package of 2 trays)
						</P>
						<P margin="0 0 0 0">
							<strong>2X </strong>(package of 2 trays)
						</P>
						<P>
							<strong>7X </strong>(full shelf)
						</P>
					</Column>
					<Column>
						<H4>Custom Paint and Branding</H4>
						<P>
							<strong>Includes: </strong>Hydro-dipped or custom-painted sides,
							front and back of machine. Custom decals on the back and sides can
							also be applied for an additional cost.
						</P>
						<P margin="0 0 0 0">
							<strong>
								<em>
									Customers can also partner with us on any custom need such as
									a direct integration or order-ahead app.
								</em>
							</strong>
						</P>
					</Column>
				</Row>
			</Column>
		);
	};

	return <div>{isMobile ? renderMobileView() : renderFullView()}</div>;
};

export default PackagesFeature;
