import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";

Modal.setAppElement(document.getElementById("root"));

const VideoModal = (props) => {
	const modalStyles = {
		content: {
			top: "55vh",
			left: "50vw",
			right: "auto",
			bottom: "auto",
			height: props.height ? props.height : "80vh",
			width: props.width ? props.width : "35vw",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			boxShadow: "0 2px 44px 0 rgba(0, 0, 0, 0.9)",
			background: "white",
			border: "0",
			padding: "0",
		},
	};
	return (
		<div>
			<Modal
				isOpen={props.modalOpen}
				onRequestClose={() => props.setModalOpen(false)}
				style={modalStyles}
				closeOnEscape={true}
			>
					{props.children}
			</Modal>
		</div>
	);
};

export default VideoModal;
