import React from "react";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import P from "../shared/P";
import Header from "../shared/Header";
import SubHeader from "../shared/SubHeader";
import twentyfour from "../assets/images/24hr.png";
import seveneleven from "../assets/images/711.png";
import apple from "../assets/images/apple.png";
import aramark from "../assets/images/aramark.png";
import compass from "../assets/images/compass.png";
import dell from "../assets/images/dell.png";
import esteelauder from "../assets/images/esteelauder.png";
import goldman from "../assets/images/goldman.png";
import juicepress from "../assets/images/juicepress.png";
import panacea from "../assets/images/panacea.png";
import redbull from "../assets/images/redbull.png";
import sprint from "../assets/images/Sprint.png";
import citi from "../assets/images/citi.png";

const ImgContainer = styled.div`
	width: 200;
	height: 100;
	margin: 2em 4em;
`;

const Stat = styled.div`
	display: flex;
	align-items: baseline;
	font-weight: 20px;
	line-height: 1.5;
	letter-spacing: 0.71px;
	color: black;
	margin-right: 3em;
	width: 500px;
`;

const StatValue = styled.span`
	font-size: 36px;
	font-weight 600;
	font-style: italic;
	letter-spacing: 1.29px;
	color: #7b964d;
  line-height: 1.5;
  margin-left: 0.5em;
`;

const CustomersDiv = (props) => {
	return (
		<Column width="90%" alignItems="flex-start">
			<Header>ViaTouch Media Inc.</Header>
			{/* <SubHeader>Stats</SubHeader>
			<Row>
				<Stat>
					Number of Machines Deployed:<StatValue>154</StatValue>
				</Stat>
				<Stat>
					Number of Machine Deployed: <StatValue>100</StatValue>
				</Stat>
			</Row>
			<Ro justifyContent="center"w margin="0 0 3em 0">
				<Stat>
					Number of Actual Sales Transactions:<StatValue>30,291</StatValue>
				</Stat>
				<Stat>
					Actual Revenue Generated: <StatValue>$207,443</StatValue>
				</Stat>
			</Row> */}
			<SubHeader>Customers</SubHeader>
			<P>
				The VICKI platform is being used by many industry leading companies for
				selling everything from high-end consumer electronics, to food and
				beverages, and CBD products.
			</P>
			<Row wrap="wrap" alignSelf="center" justifyContent="center">
				<ImgContainer>
					<img width="151" height="60" src={compass} />
				</ImgContainer>
				<ImgContainer>
					<img width="91" height="60" src={citi} />
				</ImgContainer>
				<ImgContainer>
					<img width="141" height="60" src={goldman} />
				</ImgContainer>
				<ImgContainer>
					<img width="172" height="60" src={esteelauder} />
				</ImgContainer>
			</Row>
			<Row wrap="wrap" alignSelf="center" justifyContent="center">
				<ImgContainer>
					<img width="170" height="40" src={twentyfour} />
				</ImgContainer>
				<ImgContainer>
					<img width="66" height="68" src={seveneleven} />
				</ImgContainer>
				<ImgContainer>
					<img width="199" height="57" src={aramark} />
				</ImgContainer>
				<ImgContainer>
					<img src={apple} />
				</ImgContainer>
			</Row>
			<Row wrap="wrap" alignSelf="center" justifyContent="center">
				<ImgContainer>
					<img width="143" height="60" src={sprint} />
				</ImgContainer>
				<ImgContainer>
					<img width="96" height="96" src={juicepress} />
				</ImgContainer>
				<ImgContainer>
					<img width="96" height="96" src={dell} />
				</ImgContainer>
				<ImgContainer>
					<img width="96" height="96" src={panacea} />
				</ImgContainer>
				<ImgContainer>
					<img width="127" height="78" src={redbull} />
				</ImgContainer>
			</Row>
		</Column>
	);
};
export default CustomersDiv;
