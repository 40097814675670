import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import H2 from "../shared/H2";

const Ul = styled.ul`
	list-style: none;
	font-size: 15px;
	line-height: 1.73;
	letter-spacing: 0.54px;
	margin: 0;
	padding: 0;
`;

const Li = styled.li`
	margin-bottom: 1em;
	&::before {
		content: "• ";
		color: #88a94f;
		font-weight: bold;
	}
`;

const NoStyleLi = styled.li`
	margin-bottom: 1em;

	&::before {
		content: "";
	}
`;

const Img = styled.img`
	width: 50vw;
	height: 60vw;
	align-self: center;
`;

const SpecificationFeature = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1070px)" });
	const renderFullView = () => {
		return (
			<Row justifyContent="space-around" margin="3em 0 0 0">
				<img src={props.machineFront} />
				<Column width="25%">
					<H2>Specification</H2>
					<Ul>
						<Li>
							Limited footprint: 35.7"W x 79.65"H x 32.24"D (door closed) or
							59.50"D (door open)
						</Li>
						<Li>
							Adjustable shelving for customized POGs by VICKI system by
							location if desired. Visual opening space: 25.50"W x 48"H x
							20.125"D, however ~2.5" of space is left between the back of the
							tray and the inside back wall grid. Shelves are easily adjusted.
						</Li>
						<Li>
							Intelli-shelf™ units are generally 1X and 2X sizes. They can be
							arranged in a variety of combinations providing layout flexibility
							with good pack out depending upon the SKU. Shelves can be
							programmed to light up with full RGB range of colors.
						</Li>
						<NoStyleLi>
							<img src={props.traySizes} />
						</NoStyleLi>
						<Li>Refrigerant R290 / 3.17 oz. [90g]. Cools to 34°F</Li>
						<Li>
							Standard U.S. cellular communication with point-to-point
							encryption.
						</Li>
						<Li>Approximate weight is 945 lbs.</Li>
						<Li>Uses standard 3-prong 120v AC plug.</Li>
					</Ul>
				</Column>
			</Row>
		);
	};

	const renderMobileView = () => {
		return (
			<Column justifyContent="center">
				<Column padding="3em 1em">
					<H2>Specification</H2>
					<Ul>
						<Li>
							Limited footprint: 35.7"W x 79.65"H x 32.24"D (door closed) or
							59.50"D (door open)
						</Li>
						<Li>
							Adjustable shelving for customized POGs by VICKI system by
							location if desired. Visual opening space: 25.50"W x 48"H x
							20.125"D, however ~2.5" of space is left between the back of the
							tray and the inside back wall grid. Shelves are easily adjusted.
						</Li>
						<Li>
							Intelli-shelf™ units are generally 1X and 2X sizes. They can be
							arranged in a variety of combinations providing layout flexibility
							with good pack out depending upon the SKU. Shelves can be
							programmed to light up with full RGB range of colors.
						</Li>
						<NoStyleLi>
							<img src={props.traySizes} />
						</NoStyleLi>
						<Li>Refrigerant R290 / 3.17 oz. [90g]. Cools to 34°F</Li>
						<Li>
							Standard U.S. cellular communication with point-to-point
							encryption.
						</Li>
						<Li>Approximate weight is 945 lbs.</Li>
						<Li>Uses standard 3-prong 120v AC plug.</Li>
					</Ul>
				</Column>
				<Img src={props.machineFront} />
			</Column>
		);
	};

	return <div>{isSmallScreen ? renderMobileView() : renderFullView()}</div>;
};

export default SpecificationFeature;
