import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";
import StoreButton from "./StoreButton";

const GetVickiBlack = styled.img`
	width: 322px;
	height: 322px;
`;

const LogoText = styled.div`
	display: flex;
	align-items: flex-end;
	font-size: 22.6px;
	line-height: 1.47;
	letter-spacing: 1.33px;
	margin-right: 5em;
	@media only screen and (max-width: 650px) {
		margin-right: 0;
	`;

const GetRewardsGray = styled.span`
	font-size: 24px;
	letter-spacing: 0.77px;
	color: #818181;
`;

const GetRewardsGreen = styled.span`
	font-size: 24px;
	font-weight: 800;
	font-style: italic;
	letter-spacing: 0.48px;
	color: #a8cf3a;
	text-transform: uppercase;
`;

const P = styled.p`
	font-size: 18px;
	line-height: 1.6;
	letter-spacing: 0.71px;
`;

const GetVickiStore = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1350px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 650px)" });
	const renderFullView = () => {
		return (
			<Row justifyContent="center">
				<div>
					<GetVickiBlack src={props.getVickiBlack} />
				</div>
				<Column margin="0 0 0 3em" width="30%">
					<Row margin="0 0 2em 0" alignItems="baseline">
						<LogoText>
							The
							<img src={props.getVickiLogo} />
							&nbsp;App
						</LogoText>
						<StoreButton storeLink="https://apps.apple.com/us/app/vicki-rewards/id1474082106" store={props.appStore} />
						<StoreButton
							storeLink="https://play.google.com/store/apps/details?id=vicki.rewards&hl=en_US"
							store={props.playStore}
						/>
					</Row>
					<Row>
						<GetRewardsGray>get</GetRewardsGray>
						<GetRewardsGreen>rewards</GetRewardsGreen>
					</Row>
					<Row>
						<P>
							Download the VICKI rewards app to enhance your auto-retail
							experience. Get started by downloading the app for free and
							finding your local VICKIs. Get rewards points and redeem for cash
							back towards future purchases. Manage your account and get
							receipts.
						</P>
					</Row>
				</Column>
			</Row>
		);
	};

	const renderSmallView = () => {
		return (
			<Row wrap="wrap" justifyContent="center">
				<div>
					<GetVickiBlack src={props.getVickiBlack} />
				</div>
				<Column margin={!isMobile && "0 0 0 3em"} width="30%">
					<Row wrap="wrap" alignItems="baseline">
						<LogoText>
							The
							<img src={props.getVickiLogo} />
							&nbsp;App
						</LogoText>
						<StoreButton storeLink="https://apps.apple.com/us/app/vicki-rewards/id1474082106" store={props.appStore} />
						<StoreButton
							storeLink="https://play.google.com/store/apps/details?id=vicki.rewards&hl=en_US"
							store={props.playStore}
						/>
					</Row>
					<Row>
						<GetRewardsGray>get</GetRewardsGray>
						<GetRewardsGreen>rewards</GetRewardsGreen>
					</Row>
					<Row>
						<P>
							Download the VICKI rewards app to enhance your auto-retail
							experience. Get started by downloading the app for free and
							finding your local VICKIs. Get rewards points and redeem for cash
							back towards future purchases. Manage your account and get
							receipts.
						</P>
					</Row>
				</Column>
			</Row>
		);
	};

	const renderMobileView = () => {
		return (
			<Column justifyContent="center" alignItems="center">
				<div>
					<GetVickiBlack src={props.getVickiBlack} />
				</div>
				<Column justifyContent="center" alignItems="center">
					<Column justifyContent="center" alignItems="center">
						<LogoText>
							The
							<img src={props.getVickiLogo} />
							&nbsp;App
						</LogoText>
						<StoreButton margin="1em" storeLink="" store={props.appStore} />
						<StoreButton
							margin="0 1em 1em 1em"
							storeLink="https://play.google.com/store/apps/details?id=vicki.rewards&hl=en_US"
							store={props.playStore}
						/>
					</Column>
					<Row width="90%">
						<GetRewardsGray>get</GetRewardsGray>
						<GetRewardsGreen>rewards</GetRewardsGreen>
					</Row>
					<Row width="90%">
						<P>
							Download the VICKI rewards app to enhance your auto-retail
							experience. Get started by downloading the app for free and
							finding your local VICKIs. Get rewards points and redeem for cash
							back towards future purchases. Manage your account and get
							receipts.
						</P>
					</Row>
				</Column>
			</Column>
		);
	};

	return (
		<div>
			{isSmallScreen
				? isMobile
					? renderMobileView()
					: renderSmallView()
				: renderFullView()}
		</div>
	);
};

export default GetVickiStore;
