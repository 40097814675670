import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Column from "./Column";
import Row from "./Row";
import Button from "./Button";

const H1 = styled.span`
	font-size: 56px;
	font-weight: light;
	line-height: 1.15;
	letter-spacing: 0.57px;
`;

const H2 = styled.span`
	font-size: 27px;
	font-weight: bold;
	letter-spacing: 0.96px;
	color: #88a94f;
	margin-top: 1em;
	margin-bottom: 2em;
`;

const BR = styled.p`
	margin: 0;
`;

const P = styled.p`
	font-size: 18px;
	line-height: 1.5;
	letter-spacing: 0.71px;
`;

const CompanyLogo1 = styled.img`
	width: 231px;
	height: 116px;
	margin-right: 3em;
`;

const CompanyLogo2 = styled.img`
	width: 366px;
	height: 152px;
`;

const Img = styled.img`
	@media only screen and (max-width: 1250px) {
		display: none;
		width: 0;
	}
`;

const PilotDiv = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 1250px)" });
	return (
		<Row justifyContent="center" margin={isMobile && "0 0 2em 0"}>
			<Column width="600px">
				<H1>
					Pilot Program{" "}
					<BR>
						{" "}
						with <img src={props.vickiText} />{" "}
					</BR>
				</H1>
				<P>
					We are always open for collaboration. If you have a product or service
					that you think that would be a good fit with us, let us know. We would
					like to talk with you.
				</P>

				<H2>Some of our partners:</H2>
				<Row
					wrap="wrap"
					alignItems="center"
					justifyContent={isMobile && "center"}
				>
					<CompanyLogo1 src={props.companyLogo1} />
					<CompanyLogo2 src={props.companyLogo2} />
				</Row>
				<Button
					alignSelf="center"
					margin="5em 0 0 0"
					clickable={true}
					click={() => props.setModalOpen(true)}
				>
					Get A Demo
				</Button>
			</Column>
			<Img src={props.MachineFront} />
		</Row>
	);
};

export default PilotDiv;
