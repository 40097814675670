import React, { useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { toast, Slide } from "react-toastify";
import vickiService from "../services/vicki";
import recaptchaService from "../services/recaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import Input from "./Input";
import { default as Modal } from "./StyledModal";

const Background = styled.div`
	background-color: #f1f0ed;
	width: 100%;
	padding-top: 4em;
	padding-bottom: 4em;
`;

const TextContainer = styled.div`
	width: 30%;
	padding-bottom: 1em;

	@media only screen and (max-width: 1036px) {
		width: 50%;
		align-self: center;
		text-align: center;
	}
`;

const Header = styled.span`
	font-size: 28px;
	font-weight: 600;
	line-height: 1.39;
	letter-spacing: -0.63px;
	color: #88a94f;
`;

const P = styled.p`
	line-height: 2em;
	margin: ${(props) => props.margin || "0"};
	padding: ${(props) => props.padding || "0"};
`;

const Form = styled.form`
	display: flex;
`;
const FooterContact = (props) => {
	const isBigScreen = useMediaQuery({ query: "(min-width: 1400px)" });

	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [name, setName] = useState("");
	const [message, setMessage] = useState("");
	const [isCaptchaModalOpen, setCaptchaModalOpen] = useState(false);
	const captcha = useRef();

	const handleSubmit = async () => {
		const response = await vickiService.sendEmail({
			phone: phoneNumber,
			email: email,
			company: company,
			name: name,
			body: message,
			subject: "VICKI Inquiry",
		});
		if (response.data.customer_support) {
			toast("Your email has been sent!", {
				position: "bottom-left",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				transition: Slide,
				containerId: "Success",
			});
			clearFields();
		} else {
			toast.error("An error occurred. Please try again.", {
				position: "bottom-left",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				transition: Slide,
				containerId: "Error",
			});
		}
		return;
	};

	const clearFields = () => {
		setPhoneNumber("");
		setEmail("");
		setCompany("");
		setName("");
		setMessage("");
	}

	const verifyToken = async () => {
		const recaptchaValue = captcha.current.getValue();
		const response = await recaptchaService.verifyToken(recaptchaValue);

		if (response.success) {
			handleSubmit();
			setCaptchaModalOpen(false);
		} else {
			toast.error("An error occurred. Please try again.", {
				position: "bottom-left",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				transition: Slide,
				containerId: "Error",
			});
		}
	};

	const renderFullView = () => {
		return (
			<Row wrap="wrap" justifyContent="center">
				<Column justifyContent="flex-start" margin="0 3em 0 0" width="15%">
					<Header>Get started today!</Header>
					<P margin="1em 0 1em 0">
						See how VICKI can help expand your brand while assuring the security
						of your customers and products. Contact us to arrange a demo!
					</P>
					<P>Or, contact us directly:</P>

					<P>Jim Sirignano</P>

					<P>JimSirignano@viatouchmedia.com</P>

					<P>1-866-942-0804</P>
				</Column>
				{renderForm()}
			</Row>
		);
	};

	const renderMobileView = () => {
		return (
			<Column alignItems="center" padding="0 1em">
				<Column alignItems="center" justifyContent="center" width="100%">
					<Header>Get started today!</Header>
					<P padding="0 1em">
						See how VICKI can help expand your brand while assuring the security
						of your customers and products. Contact us to arrange a demo!
					</P>
					<P>Or, contact us directly:</P>

					<P>Jim Sirignano</P>

					<P>JimSirignano@viatouchmedia.com</P>

					<P>1-866-942-0804</P>
				</Column>
				{renderMobileForm()}
			</Column>
		);
	};

	const renderForm = () => {
		return (
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					setCaptchaModalOpen(true);
				}}
			>
				<Modal
					modalOpen={isCaptchaModalOpen}
					setModalOpen={() => setCaptchaModalOpen()}
					headerDisplay="none"
					width="auto"
					height="auto"
					background="none"
					boxShadow="none"
					overflow="hidden"
				>
					<ReCAPTCHA
						ref={captcha}
						sitekey="6Lcgy7EZAAAAALr7XZhG5rbPZlcAZAnQy5RMyj3e"
						onChange={verifyToken}
					/>
				</Modal>
				<Column justifyContent="flex-start">
					<Input
						type="text"
						id="name"
						labelText="name"
						value={name}
						setValue={setName}
						required={true}
					/>
					<Input
						type="text"
						id="company"
						labelText="company"
						value={company}
						setValue={setCompany}
					/>
					<Input
						type="tel"
						id="phoneNumber"
						labelText="phone number"
						value={phoneNumber}
						setValue={setPhoneNumber}
						required={true}
					/>
				</Column>
				<Column alignItems="center" margin="0 0 0 3em">
					<Input
						type="email"
						id="email"
						labelText="email"
						value={email}
						setValue={setEmail}
						required={true}
					/>
					<Input
						type="textarea"
						id="message"
						labelText="message"
						value={message}
						setValue={setMessage}
						required={true}
					/>
					<Input type="submit" id="submit" value="Submit" />
				</Column>
			</Form>
		);
	};

	const renderMobileForm = () => {
		return (
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					setCaptchaModalOpen(true);
				}}
			>
				<Modal
					modalOpen={isCaptchaModalOpen}
					setModalOpen={() => setCaptchaModalOpen()}
					headerDisplay="none"
					width="100%"
					height="auto"
					background="none"
					boxShadow="none"
					overflow="hidden"
				>
					<ReCAPTCHA
						ref={captcha}
						sitekey="6Lcgy7EZAAAAALr7XZhG5rbPZlcAZAnQy5RMyj3e"
						onChange={verifyToken}
					/>
				</Modal>
				<Column justifyContent="center">
					<Input
						type="text"
						id="name"
						labelText="name"
						width="80vw"
						value={name}
						setValue={setName}
						required={true}
					/>
					<Input
						type="text"
						id="company"
						labelText="company"
						width="80vw"
						value={company}
						setValue={setCompany}
					/>
					<Input
						type="tel"
						id="phoneNumber"
						labelText="phone number"
						width="80vw"
						value={phoneNumber}
						setValue={setPhoneNumber}
						required={true}
					/>

					<Input
						type="email"
						id="email"
						labelText="email"
						width="80vw"
						value={email}
						setValue={setEmail}
						required={true}
					/>
					<Input
						type="textarea"
						id="message"
						labelText="message"
						width="80vw"
						value={message}
						setValue={setMessage}
						required={true}
					/>
					<Input type="submit" id="submit" value="Submit" alignSelf="center" />
				</Column>
			</Form>
		);
	};
	return (
		<Background>
			{isBigScreen ? renderFullView() : renderMobileView()}
		</Background>
	);
};

export default FooterContact;
