import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import H3 from "../shared/H2";
import H4 from "../shared/H4";
import P from "../shared/P";

const Img = styled.img`
	padding-top: 1em;
	margin-left: auto;
	@media only screen and (max-width: 1144px) {
		width: auto;
		height: auto;
	}
	@media only screen and (max-width: 1086px) {
		width: 50vw;
		height: 50vh;
		align-self: flex-end;
	}
	@media only screen and (max-width: 700px) {
		display: none;
	}
`;

const AIFeature = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
	return (
		<Row background="#f1f0ed" width="100%">
			<Column
				width={!isMobile && "25%"}
				justifyContent="flex-start"
				margin={!isMobile && "3em 2em 0 8em"}
				padding={isMobile && "1em 1em"}
			>
				<H3>Artificial Intelligence</H3>
				<P>
					VICKI is smart. She can learn about your products and answer
					customers' questions. She can also highlight and promote new products
					so you can sell more!
				</P>
				<H4>Natural Language Processing (NLP)</H4>
				<P>
					VICKI listens to your customers' questions and can accurately answer
					based on natural language processing.
				</P>
				<H4>VICKI Chat</H4>
				<P>
					Customers can also ask VICKI questions through VICKI Chat in our app.
					In addition to finding location and products, VICKI Chat can help with
					any other questions customers may have.
				</P>
			</Column>
			<Img src={props.vickiFront} />
		</Row>
	);
};

export default AIFeature;
