import React from "react";
import { useMediaQuery } from "react-responsive";
import VickiText from "../../assets/images/vicki_text.png";
import Citi from "../../assets/images/citi.png";
import Delivery from "../../assets/images/Delivery.com_Logo.png";
import takeoutMachine from "../../assets/images/Order_For_PickUp_Front.png";
import takeoutApp from "../../assets/images/takeout_app.png";
import Package from "../../assets/images/Package.png";
import QuickServe from "../../assets/images/QuickServe.png";
import OrderDeliver from "../../assets/images/Order_Deliver.png";

import Column from "../../components/Column";
import Row from "../../components/Row";
import TakeoutDiv from "../../components/TakeoutDiv";
import TakeoutIcon from "../../components/TakeoutIcon";

const Takeout = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 460px)" });
	return (
		<Column padding="15vh 0 0 0" alignItems="center" justifyContent="center">
			<TakeoutDiv
				vickiText={VickiText}
				companyLogo1={Citi}
				companyLogo2={Delivery}
				takeoutApp={takeoutApp}
				takeoutMachine={takeoutMachine}
			/>
			<Row
				wrap="wrap"
				justifyContent="center"
				margin="5em 0"
				alignItems="center"
			>
				<TakeoutIcon
					icon={QuickServe}
					width="143px"
					height="143px"
					text="Quick Serve Pre-Order"
					margin={!isMobile && "0 5em 0 0"}
				/>
				<TakeoutIcon
					icon={Package}
					width="143px"
					height="143px"
					text="Packages & Mail"
					margin={!isMobile && "0 5em 0 0"}
				/>
				<TakeoutIcon
					icon={OrderDeliver}
					width="143px"
					height="143px"
					text="Food Delivery"
				/>
			</Row>
		</Column>
	);
};

export default Takeout;
