import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const AppContainer = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  margin-top:130px;
`;

const Container = styled.section`
  margin: 20px 0;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 26pt;
  color: black;
  font-weight: bold;
`;

const SubTitle = styled.h2`
  font-size: 20px;
  color: #5c9820;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: black;
  text-align: justify;
`;

const ListItem = styled.li`
  font-size: 16px;
  color: black;
  margin-left: 30px;
  margin: 10px 0;
  list-style-type: none;
  
  &:before {
    content: attr(data-list-text);
    margin-right: 10px;
  }
`;

const LicensingAgreements = () => {
    return (
        <AppContainer>
            <Header />
            <Container>
                <Paragraph>
                    Viatouch Media Inc. – VICKI Security Halo
                </Paragraph>
                <Paragraph>
                    Clover Apps, including Rotating Media, Ask VICKI Assistant about Clover Products and Pricing ,
                    Fraud Prevention, SOS Notifications, Transaction Analytics, and Clover Halobox App (collectively,
                    the “Software”)
                </Paragraph>
                <Paragraph>THIS SOFTWARE END USER LICENSE AGREEMENT (“AGREEMENT”) IS A LEGAL
                    AGREEMENT BETWEEN YOU (EITHER AN INDIVIDUAL OR, IF PURCHASED OR
                    OTHERWISE ACQUIRED BY OR FOR AN ENTITY, AN ENTITY) AND VIATOUCH MEDIA,
                    INC. (“VIATOUCH”) READ IT CAREFULLY BEFORE COMPLETING THE INSTALLATION
                    PROCESS AND USING THE SOFTWARE. IT PROVIDES A LICENSE TO USE THE
                    SOFTWARE AND CONTAINS WARRANTY INFORMATION AND LIABILITY
                    DISCLAIMERS. BY SELECTING THE “I ACCEPT” BUTTON OR UNZIPPING, COPYING THE
                    CONTENTS TO A SERVER OR ADDING CONTENTS TO A NUGET FEED AND USING THE
                    SOFTWARE, YOU ARE CONFIRMING YOUR ACCEPTANCE OF THE SOFTWARE AND
                    AGREEING TO BECOME BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT
                    AGREE TO BE BOUND BY THESE TERMS, THEN DO NOT INSTALL, COPY OR USE THE
                    SOFTWARE.</Paragraph>

                <SubTitle>Grant of License</SubTitle>
                <Paragraph>
                    Subject to your continuous compliance with this Agreement and payment of the applicable
                    subscription fees, ViaTouch grants to you a non-exclusive and non-transferable license to use, solely
                    by and through those individuals authorized to use the Software pursuant to the license granted under
                    this Agreement (“Authorized Users”), the Software and corresponding documentation, associated
                    media, printed materials, and online or electronic documentation, and all updates or upgrades of the
                    above that are provided to you (collectively the “Product”) in the manner described below. The
                    license granted hereunder is solely for the benefit of you and a number of Authorized Users
                    identified on the applicable order you placed and ViaTouch accepted for the Software. You may not
                    provide access to the Product to non-licensed persons. The number of users of the Software shall not
                    exceed the number of Authorized Users licensed identified in the applicable order.
                </Paragraph>

                <SubTitle>Ownership</SubTitle>
                <ol>
                    <ListItem data-list-text="(a)">
                        ViaTouch and its suppliers and partners retain all right, title, and interest, including all copyright
                        and intellectual property rights, in and to, the Product, its maintenance releases, upgrades, and
                        improvements, and all copies thereof. All rights not specifically granted in this Agreement,
                        including federal and international copyrights, are reserved by ViaTouch, its suppliers and its
                        partners. The only licenses granted hereunder are those expressly stated in this Agreement, and
                        there are no implied licenses granted hereunder. ViaTouch retains the ownership of each copy of
                        the Product and any subsequent copies of the Product.
                    </ListItem>
                    <ListItem data-list-text="(b)">
                        All names, words, phrases, logos, symbols, designs, images, trade dress or a combination of the
                        foregoing, contained in the Product are trademarks or registered trademarks of ViaTouch in the
                        United States and/or other countries. Third party trademarks, trade names, product names and
                        logos may be the trademarks or registered trademarks of their respective owners. You may not
                        remove or alter any trademark, trade names, product names, logo, copyright or other proprietary
                        notices, legends, symbols or labels in the Product. This Agreement does not authorize you to use
                        ViaTouch’s or its licensors’ names or any of their respective trademarks.
                    </ListItem>
                </ol>

                <SubTitle>Terms and Conditions of Use</SubTitle>
                <Paragraph>Except as otherwise provided herein, in no instance may you:</Paragraph>
                <ol>
                    <ListItem data-list-text="(a)">
                        transfer, distribute, rent, sub-license, or lease the Product, or use the Product in any way to
                        the detriment of ViaTouch or in contravention of this Agreement;
                    </ListItem>
                    <ListItem data-list-text="(b)">
                        exceed the number of Authorized Users permitted for the Software;
                    </ListItem>
                    <ListItem data-list-text="(c)">
                        alter, modify, merge, translate or adapt the Product, or portions thereof including, but not
                        limited to, reverse engineering, translation, decompiling, disassembling, or creating
                        derivative works;
                    </ListItem>
                    <ListItem data-list-text="(d)">
                        make copies of the Product, or portions thereof;
                    </ListItem>
                    <ListItem data-list-text="(e)">
                        remove any proprietary or copyright notices or labels on the Product; or
                    </ListItem>
                    <ListItem data-list-text="(f)">
                        export or use the Product in violation of any laws, rules or regulations, including, without
                        limitation, United States export laws and regulations.
                    </ListItem>
                </ol>

                <SubTitle>Software Description, Service and Service Term</SubTitle>
                <Paragraph>
                    The Software provides business insights for managers and business owners: The Software provides
                    merchants with a way to incorporate NLP, Security and Analytics complimentary to Clover Units.
                </Paragraph>
                <Paragraph>The features and functionality of the Software may be modified from time to time by ViaTouch in its
                    discretion.</Paragraph>
                <Paragraph>The Software is offered both as a free and on a pre-paid subscription basis. For the pre-paid
                    subscription, you shall submit each request or purchase order for each subscription to ViaTouch’s
                    designed website or to ViaTouch directly (each an “Order”). Your initial subscription to use the
                    Product will begin on the day the license key is sent by ViaTouch. Provided that you comply with the
                    obligations under this Agreement and makes full payment of the fees, ViaTouch will either extend
                    the then-current license key or issue another license key for each subsequent 1-month period after the
                    end of the previous 1-month period.Payment to ViaTouch for each Order shall be made in U.S. dollars. All fees are payable in advance in
                    the manner set forth in the Order and are non-refundable. Any renewal of the Software subscription
                    shall not be effective until the fees for such renewal have been paid in full.</Paragraph>

                <SubTitle>Termination for Cause</SubTitle>
                <Paragraph>
                    This Agreement may be terminated by you at any time by uninstalling the Software. Your
                    termination of this Agreement shall be effective as of the end of the current billing period in which
                    you uninstall the Software.
                </Paragraph>
                <Paragraph>ViaTouch may terminate this Agreement and/or suspend the provision of the services immediately
                    for: illegal, fraudulent, excessive or improper use of the Product; if any portion of an invoice not
                    reasonably disputed in good faith as herein provided remains unpaid when due; or for breach, not
                    subject to cure.</Paragraph>
                <Paragraph>Your right to use the Product will terminate immediately if you use or attempt to use the Product in a
                    way that is contrary to this Agreement, the determination of which will be in ViaTouch’s sole
                    discretion.</Paragraph>
                <Paragraph>Upon termination or expiration of this Agreement and the Software Subscription:</Paragraph>
                <ol>
                    <ListItem data-list-text="i.">
                        You will be prevented from accessing and using any or all functionality of the
                        Product;
                    </ListItem>
                    <ListItem data-list-text="ii.">
                        You will not have access or the ability to use or access any updates,
                        improvements, new features, or any other changes to the Product;
                    </ListItem>
                    <ListItem data-list-text="iii.">
                        All technical support shall cease; and
                    </ListItem>
                    <ListItem data-list-text="iv.">
                        You may lose access to all data stored in or via the Product.
                    </ListItem>
                </ol>
                <Paragraph>Upon termination or expiration of this Agreement and the Software subscription, you must
                    immediately cease use of the Product, uninstall it from the appliance, computer or device on which it
                    is installed and destroy all copies of the Product, all of its component parts, documentation, and
                    disks, and confirm to ViaTouch’s reasonable satisfaction that you have taken the foregoing actions.</Paragraph>
                <SubTitle>Confidentiality, Registration and Passwords.</SubTitle>
                <Paragraph>You agree to maintain the confidentiality of any and all of your login credentials. You are
                    responsible for all actions taken with your login credentials and stored information. If you permit
                    another person to have access to your login credentials, we will treat this as if you have authorized
                    such use and you will be liable for all actions taken by those persons. You are wholly responsible for
                    the use of your login credentials as provided in this Agreement.</Paragraph>
                <SubTitle>Service Limitations</SubTitle>
                <ol>
                    <li>ViaTouch may alter which devices and browsers are approved as compatible with the Software
                        in its discretion from time-to-time. </li>
                    <li>Viatouch may perform maintenance on the Software from time to time which may result in
                        service interruptions, delays, or errors. ViaTouch will not be liable for any such interruptions,
                        delays, errors, or bugs. You agree that we may contact you in order to assist you with the
                        Software and obtain information needed to identify and fix any errors.</li>
                </ol>
                <SubTitle>Limitations of Liability</SubTitle>
                <Paragraph>TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER NO
                    CIRCUMSTANCES AND UNDER NO LEGAL THEORY, TORT, CONTRACT, OR
                    OTHERWISE, SHALL VIATOUCH OR ITS SUPPLIERS, PARTNERS, LICENSORS, OR
                    RESELLERS BE LIABLE TO LICENSEE OR ANY OTHER PERSON FOR ANY DIRECT,
                    INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES OF ANY CHARACTER
                    INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS OR REVENUE,
                    DATA OR INFORMATION LOSS, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER
                    FAILURE OR MALFUNCTION, OR ANY AND ALL OTHER COMMERCIAL DAMAGES OR
                    LOSSES ARISING OUT OF THE USE OR INABILITY TO USE THE PRODUCT OR
                    DOCUMENTATION, INCLUDING, INCURRED BY YOU OR ANY THIRD PARTY, IN THE
                    PERFORMANCE OF THIS AGREEMENT, OR RELATED TO THE PRODUCTS, WHETHER IN
                    AN ACTION IN CONTRACT OR TORT, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES, EXCEPT TO THE EXTENT THAT DAMAGES FOR BREACH OF
                    CONFIDENTIALITY OR PATENT, COPYRIGHT OR TRADE SECRET INFRINGEMENT ARE
                    DEEMED TO BE CONSEQUENTIAL IN NATURE. NOTWITHSTANDING ANY DAMAGES
                    YOU MIGHT INCUR FOR ANY REASON WHATSOEVER, VIATOUCH AND ANY OF ITS
                    SUPPLIERS TO YOU SHALL NOT BE LIABLE FOR ANY REASON OR UPON ANY CAUSE
                    OF ACTION OR CLAIM WHATSOEVER. THIS LIMITATION APPLIES TO ALL CAUSES OF
                    ACTION OR CLAIMS IN THE AGGREGATE, INCLUDING WITHOUT LIMITATION TO
                    BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT LIABILITY,
                    MISREPRESENTATIONS, CLAIMS FOR FAILURE TO EXERCISE DUE CARE IN THE
                    PERFORMANCE OF SERVICES HEREUNDER AND OTHER TORTS. THIS LIMITATION OF
                    LIABILITY SHALL NOT APPLY TO LIABILITY FOR DEATH OR PERSONAL INJURY TO
                    THE EXTENT APPLICABLE LAW PROHIBITS SUCH LIMITATION. BOTH PARTIES
                    UNDERSTAND AND AGREE THAT THE REMEDIES, EXCLUSIONS AND LIMITATIONS
                    HEREIN ALLOCATE THE RISKS OF PRODUCT NONCONFORMITY BETWEEN THE
                    PARTIES AS AUTHORIZED BY THE UNIFORM COMMERCIAL CODE AND/OR OTHER
                    APPLICABLE LAWS. THE PRICES FOR PRODUCTS HEREIN REFLECT, AND ARE SET IN
                    RELIANCE UPON, THIS ALLOCATION OF RISK AND THE EXCLUSION OF
                    CONSEQUENTIAL DAMAGES AND LIMITATIONS OF LIABILITY SET FORTH IN THIS
                    AGREEMENT. THIS EXCLUSION ALSO INCLUDES ANY LIABILITY THAT MAY ARISE
                    OUT OF THIRD-PARTY CLAIMS AGAINST YOU. ANY ACTION BY A PARTY MUST BE
                    BROUGHT WITHIN THE EARLIER OF TWO (2) YEARS AFTER SUCH PARTY OBTAINS
                    KNOWLEDGE OF THE FACTS LEADING TO SUCH ACTION AND THE APPLICABLE
                    STATUTE OF LIMITATIONS.</Paragraph>
                <SubTitle>Disclaimer Of Warranties</SubTitle>
                <ol>
                    <ListItem data-list-text="(a)">
                        THE PRODUCT, INCLUDING THE SOFTWARE AND DOCUMENTATION ARE
                        PROVIDED TO YOU “AS IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT
                        WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER
                        APPLICABLE LAW, VIATOUCH, ON ITS OWN BEHALF AND ON BEHALF OF ITS
                        SUPPLIERS, PARTNERS, AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS
                        AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER
                        EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE
                        PRODUCT, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON- INFRINGEMENT, AND
                        WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
                        PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE
                        FOREGOING, VIATOUCH PROVIDES NO WARRANTY OR UNDERTAKING, AND
                        MAKES NO REPRESENTATION OF ANY KIND THAT THE LICENSED SOFTWARE
                        WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE
                        COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS,
                        SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY
                        PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE, OR THAT ANY
                        ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
                    </ListItem>
                    <ListItem data-list-text="(b)">
                        IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE
                        SOFTWARE OR DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES,
                        THEN IN SUCH AN EVENT ALL SUCH WARRANTIES ARE DEEMED TO BE LIMITED
                        IN DURATION TO NINETY (90) DAYS FROM THE DATE OF YOUR INSTALLATION
                        OF THE SOFTWARE.
                    </ListItem>
                </ol>
                <Paragraph>ViaTouch shall have no liability or responsibility if the Software has been altered in any way, or for
                    any failure that arises out of use of the Software with any hardware, software, platform, operating
                    system or any combination of the foregoing, other than a hardware configuration, platform or
                    operating system recommended in accordance with the Software’s documentation</Paragraph>
                <SubTitle>Basis of the Bargain</SubTitle>
                <Paragraph>The Warranty Disclaimer and Limitation of Liability set forth herein are fundamental elements of the
                    basis of the agreement between ViaTouch and you. ViaTouch would not be able to provide the
                    Software on an economic basis without such limitations. Such Warranty Disclaimer and Limitation
                    of Liability inure to the benefit of ViaTouch’s licensors, successors and assigns.</Paragraph>
                <SubTitle>General Provisions</SubTitle>
                <ol>
                    <ListItem data-list-text="(a)">Reservation of Rights. ViaTouch retains all rights not expressly granted herein. Nothing in this
                        Agreement constitutes a waiver of ViaTouch’s rights under United States copyright law.</ListItem>
                    <ListItem data-list-text="(b)">
                        Severability. If any part of this Agreement is found to be invalid or unenforceable by any law,
                        rule, order or regulation of any government, or by the final determination of any state or federal
                        court, that part of this Agreement shall be amended to achieve as nearly as possible the same
                        economic effect as the original provision and the remaining provisions of this Agreement shall
                        remain in full force and effect.
                    </ListItem>
                    <ListItem data-list-text="(c)">
                        Entire Agreement. No provisions in your purchase orders, license agreements or in any other
                        business forms employed by you shall add to or supersede the terms and conditions of this
                        Agreement, which shall exclusively govern the relationship of the parties. This Agreement,
                        together with the schedules attached hereto, constitute and contain the complete agreement and
                        understanding of the parties with respect to the subject matter hereof and supersede any and all
                        prior correspondence, agreements, representations, statements, negotiations and undertakings
                        between the parties relating to the subject matter hereof. Amendments to this Agreement must
                        be in writing, specifying such amendment, signed by duly authorized representatives of both
                        parties.
                    </ListItem>
                    <ListItem data-list-text="(d)">
                        Governing Law. The laws of the state of New York shall govern the construction and
                        enforceability of this Agreement without regard to its conflict of laws principles. The parties
                        agree that any action arising under or relating to this Agreement or the Products shall lie within
                        the exclusive jurisdiction of any State or Federal court located in the County of Suffolk, State of
                        New York. Licensee consents to the exercise of jurisdiction by any State or Federal court
                        located in the County of Suffolk, State of New York and agrees that process may be served on
                        Licensee in any such action by mailing it to Licensee at the address set forth in the applicable
                        order. In each case this Agreement shall be construed and enforced without regard to the United
                        Nations Convention on the International Sale of Goods. If either party is compelled to seek
                        judicial enforcement of its rights under this Agreement, the prevailing party in any such action
                        shall be entitled to recover its costs incurred in such action, including reasonable attorneys’ fees.
                    </ListItem>
                    <ListItem data-list-text="(e)">
                        No Waiver. No waiver by either party of any default shall operate as a waiver of any other
                        default or of a similar default on a future occasion. No waiver of any term or condition shall be
                        effective unless in writing and signed by the party against whom enforcement of the waiver is
                        sought. Neither party shall be responsible for any failure to perform any obligation hereunder
                        (except a failure to pay) due to causes beyond its reasonable control.
                    </ListItem>
                    <ListItem data-list-text="(f)">
                        Notices. All notices, requests, consents, claims, demands, waivers, and other communications
                        hereunder shall be in writing and shall be deemed to have been given: (i) when delivered by
                        hand (with written confirmation of receipt); (ii) when received by the addressee if sent by a
                        nationally recognized overnight courier (receipt requested); (iii) on the date sent by facsimile or
                        email (with confirmation of transmission) if sent during normal business hours of the recipient,
                        and on the next business day if sent after normal business hours of the recipient; or (iv) on the
                        fifth day after the date mailed, by certified or registered mail, return receipt requested, postage
                        prepaid. Such communications must be sent to the respective parties at the addresses set forth on
                        the order form or to such other address as may be designated by a party from time to time in
                        accordance with this Section and the applicable order.
                    </ListItem>
                    <ListItem data-list-text="(g)">
                        Assignment. You shall not assign or otherwise transfer any of its rights, or delegate or otherwise
                        transfer any of its obligations or performance, under this Agreement, in each case whether
                        voluntarily, involuntarily, by operation of law or otherwise, without ViaTouch’s prior written
                        consent, which consent may be given or withheld in its sole discretion. For purposes of the
                        preceding sentence, and without limiting its generality, any merger, consolidation, or
                        reorganization involving you (regardless of whether you are a surviving or disappearing entity)
                        will be deemed to be a transfer of rights, obligations, or performance under this Agreement for
                        which ViaTouch’s prior written consent is required. No delegation or other transfer will relieve
                        you of any of its obligations or performance under this Agreement. Any purported assignment,
                        delegation, or transfer in violation of this Section 19(f) is void. ViaTouch may freely assign or
                        otherwise transfer all or any of its rights, or delegate or otherwise transfer all or any of its
                        obligations or performance, under this Agreement without your consent. This Agreement is
                        binding upon and inures to the benefit of the parties hereto and their respective permitted
                        successors and assigns.
                    </ListItem>
                    <ListItem data-list-text="(h)">
                        Successors and Assigns. This Agreement is for the sole benefit of the parties hereto and their
                        respective successors and permitted assigns and nothing herein, express or implied, is intended
                        to or shall confer on any other person any legal or equitable right, benefit, or remedy of any
                        nature whatsoever under or by reason of this Agreement.
                    </ListItem>
                    <ListItem data-list-text="(i)">
                        Headings. The headings in this Agreement are for reference only and do not affect the
                        interpretation of this Agreement.
                    </ListItem>
                </ol>
                <SubTitle>Additional Clover Terms</SubTitle>
                <Paragraph>Reasonable advance notice will be provided to current subscribers of an app before Viatouch ceases
                    making services available through app, takes down an app, or removes it from current distribution
                    due to:</Paragraph>
                <ol>
                    <ListItem data-list-text="(a)">
                        an allegation or actual infringement of any intellectual property right or right of publicity or
                        privacy of any third party;
                    </ListItem>
                    <ListItem data-list-text="(b)">
                        an allegation or actual defamation;
                    </ListItem>
                    <ListItem data-list-text="(c)">
                        an allegation or determination that an app does not comply with applicable law;
                    </ListItem>
                    <ListItem data-list-text="(d)">
                        ViaTouch ceasing to do business; or
                    </ListItem>
                    <ListItem data-list-text="(e)">
                        ViaTouch filing a petition in bankruptcy, dissolving or otherwise failing or unable to pay debts
                        as they become due.
                    </ListItem>
                </ol>
                <Paragraph>An app may be removed at any time from the “App Market” if it is determined that the app:</Paragraph>
                <ol>
                    <ListItem data-list-text="(a)">
                        may infringe or otherwise violate the intellectual property rights or any other rights of any third
                        party;
                    </ListItem>
                    <ListItem data-list-text="(b)">
                        violates any applicable law, regulation, card association rule, or is subject to an injunction;
                    </ListItem>
                    <ListItem data-list-text="(c)">
                        violates the Clover App Market Developer Program Policies, which can be viewed by visiting
                        the following links: <a href='https://getvicki.com/static/media/privacy_policy.150dd4ed.pdf' target='_blank' >https://getvicki.com/static/media/privacy_policy.150dd4ed.pdf</a>  and
                        <a style={{ marginLeft: 3 }} href='https://getvicki.com/static/media/licensing_and_agreement.150dd4ed.pdf' target='_blank'>https://getvicki.com/static/media/licensing_and_agreement.150dd4ed.pdf</a>
                    </ListItem>
                    <ListItem data-list-text="(d)">
                        is being distributed in a manner that is in breach of the Clover Developer Terms;
                    </ListItem>
                    <ListItem data-list-text="(e)">
                        may create liability for Clover; or
                    </ListItem>
                    <ListItem data-list-text="(f)">
                        may contain a virus, malware or spyware, or may have an adverse impact on Clover’s systems.
                    </ListItem>
                </ol>
                <Paragraph>A subscriber may cancel their subscription at any time before the end of the applicable billing cycle,
                    and upon cancellation, the subscriber will:</Paragraph>
                <ol>
                    <ListItem data-list-text="(a)">
                        Not receive a refund for the billing period during which the subscriber cancels its subscription;
                        and
                    </ListItem>
                    <ListItem data-list-text="(b)">
                        be able to access its subscription and receive issues and updates of the relevant subscription
                        until the subscription termination date.
                    </ListItem>
                </ol>
                <Paragraph>If you desire a refund of any subscription fees, you must request such refund directly from ViaTouch.
                    Clover and its affiliates do not have any responsibility or liability to a subscriber of an app in any
                    way with respect to the subscriber’s use of an app.</Paragraph>
                <SubTitle>Other</SubTitle>
                <Paragraph>Merchant specific data pulled from Clover API is displayed in user interface in tabular form or
                    visualized as graph. And that data can also be accessible from user interface in the form of exported
                    via CSV data files, image files graphs via the Viatouch Media Client Dashboard, which grants
                    merchants’ access to the security settings, halo box details, transaction analytics, media and VICKI
                    Assistant setup. For the avoidance of doubt, the Clover API is Clover's Cloud REST Services that
                    allow Viatouch to access merchant information including merchant product information, and
                    consumer transaction details including what they purchased, and what kind of payment type a
                    consumer used (eg. EMV, NFC, MSR).</Paragraph>
                <Paragraph>All responsibility for extracted data from user interface lies on the user who extracted the data. It
                    becomes such person’s responsibility on how they manage exported data. The same applies to
                    automatic emailing reports.</Paragraph>
                <SubTitle>Contact Us</SubTitle>
                <Paragraph>If you have any questions or concerns regarding these Terms or our Apps, contact us at
                    <a href="mailto:support@viatouchmedia.com" style={{ marginLeft: 3 }}>support@viatouchmedia.com</a>  or 1-866-942-0804.</Paragraph>
            </Container>
            <Footer />
        </AppContainer>

    );
};

export default LicensingAgreements;