import React from "react";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";
import CVImage from "./CVImage";

const Header = styled.div`
	font-size: 27px;
	font-weight: 600;
	letter-spacing: 0.96px;
	color: #88a94f;
	margin-left: 1.7em;
`;

const CVDetails = (props) => {
	return (
		<Row justifyContent="center" margin="2em 0 5em 0">
			<Column>
				<Header>Sensor Fusion: </Header>
				<Row wrap="wrap" justifyContent="center">
					{props.CVInfo.map((info) => (
						<CVImage
							key={info}
							image={info[1]}
							caption={info[0]}
							details={info[2]}
						/>
					))}
				</Row>
			</Column>
		</Row>
	);
};

export default CVDetails;
