import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  padding: 20px;
  background-color: #343a40;
  color: white;
  text-align: center;
`;

const Header = () => (
  <HeaderContainer>
    <h1>End User License Agreement</h1>
  </HeaderContainer>
);

export default Header;
