import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Link from "../shared/Link";

const TextContainer = styled.div`
	width: 50%;
	@media only screen and (max-width: 1445px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 90%;
}
`;

const QuoteText = styled.span`
	font-size: 28px;
	line-height: 1.46;
	letter-spacing: 1.08px;
	color: #aad264;
	margin-bottom: 3em;
`;

const Reference = styled.p`
	font-size: 14px;
	font-weight: bold;
	line-ehight: 1.64;
	letter-spacing: 1.16px;
	color: white;
	margin: 0;
	text-align: right;
	align-self: flex-start;

	@media only screen and (max-width: 1445px) {
		width: 10em;
	}
`;

const Quote = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 520px)" });
	return (
		<Column
			padding="5em 0 5em 0 "
			background="#212121"
			width="100%"
			alignItems="center"
		>
			<TextContainer>
				<QuoteText>
					"The VICKI Platform is the intelligence behind the VICKI smart store, and the worlds first AI driven retail system. We are excited to continue to define the future of retail through data, advanced technologies and frictionless experiences."
				</QuoteText>
				<Column width="18%" margin={isMobile ? "3em 0 5em 0" : "3em 0 5em auto"}	>
					<Reference>-Tom Murn</Reference>
					<Reference>CEO & President</Reference>
				</Column>
			</TextContainer>
			<Link href="https://www.linkedin.com/in/tom-murn-6a60a813/">
				Learn More
			</Link>
		</Column>
	);
};

export default Quote;
