import React from "react";
import { caseStudies } from "./data";
import Column from "../../components/Column";
import CustomersDiv from "../../components/CustomersDiv";
import CaseStudy from "../../components/CaseStudy";
import StyledCarousel from "../../components/StyledCarousel";

const Customers = (props) => {
	return (
		<div style={{ overflowX: "hidden" }}>
			<Column margin="15vh 0 0 0" alignItems="center">
				<CustomersDiv />
				<StyledCarousel>
					{caseStudies.map((study) => (
						<CaseStudy
							case={study[0]}
							summary={study[1]}
							image={study[2]}
							pdf={study[3]}
						/>
					))}
				</StyledCarousel>
			</Column>
		</div>
	);
};

export default Customers;
