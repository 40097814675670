import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import vickiService from "../services/vicki";
import recaptchaService from "../services/recaptcha";
import { toast, Slide } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import Column from "./Column";
import Input from "./Input";
import { default as Modal } from "./StyledModal";

const ContactForm = (props) => {
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [name, setName] = useState("");
	const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
	const [isCaptchaModalOpen, setCaptchaModalOpen] = useState(false);
	const captcha = useRef();

	const handleSubmit = async () => {
		const response = await vickiService.sendEmail({
			phone: phoneNumber,
			email: email,
			company: company,
			name: name,
			subject: "Demo Request",
		});
		if (response.data.customer_support) {
			toast("Your email has been sent!", {
				position: "bottom-left",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				transition: Slide,
				containerId: "Success",
			});
			clearFields();
		} else {
			toast.error("An error occurred. Please try again.", {
				position: "bottom-left",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				transition: Slide,
				containerId: "Error",
			});
		}
		return;
	};

	const clearFields = () => {
		setPhoneNumber("");
		setEmail("");
		setCompany("");
		setName("");
	}

	const verifyToken = async () => {
		const recaptchaValue = captcha.current.getValue();
		const response = await recaptchaService.verifyToken(recaptchaValue);

		if (response.success) {
			handleSubmit();
			setCaptchaModalOpen(false);
		} else {
			toast.error("An error occurred. Please try again.", {
				position: "bottom-left",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				transition: Slide,
				containerId: "Error",
			});
		}
	};

	const width = () => {
		if (isMobile) {
			return "80vw";
		} else {
			return "500px";
		}
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				setCaptchaModalOpen(true);
			}}
		>
			<Modal
				modalOpen={isCaptchaModalOpen}
				setModalOpen={() => setCaptchaModalOpen()}
				headerDisplay="none"
				width={isMobile ? "100%" : "auto"}
				height="auto"
				background="none"
				boxShadow="none"
				overflow="hidden"
			>
				<ReCAPTCHA
					ref={captcha}
					sitekey="6Lcgy7EZAAAAALr7XZhG5rbPZlcAZAnQy5RMyj3e"
					onChange={verifyToken}
				/>
			</Modal>
			<Column width={width()}>
				<Input
					type="text"
					required={true}
					id="name"
					labelText="name"
					border={true}
					width="100%"
					margin="0 0 1em 0"
					containerMa
					value={name}
					setValue={setName}
					rgin="1em 0 0 0"
				/>
				<Input
					type="text"
					required={true}
					id="company"
					labelText="company"
					border={true}
					width="100%"
					margin="0 0 1em 0"
					value={company}
					setValue={setCompany}
				/>
				<Input
					type="tel"
					required={true}
					id="contactNumber"
					labelText="contact number"
					border={true}
					width="100%"
					margin="0 0 1em 0"
					value={phoneNumber}
					setValue={setPhoneNumber}
				/>
				<Input
					type="email"
					required={true}
					id="email"
					labelText="email"
					border={true}
					width="100%"
					margin="0 0 1em 0"
					value={email}
					setValue={setEmail}
				/>
				<Input type="submit" id="submit" value="Get me a demo!" />
			</Column>{" "}
		</form>
	);
};

export default ContactForm;
