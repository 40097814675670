import React from "react";
import styled from "styled-components";
import Column from "./Column";

const Img = styled.img`
	width: ${(props) => (props.width ? props.width : "")};
	height: ${(props) => (props.height ? props.height : "")};
`;

const H1 = styled.span`
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0.96px;
	color: #88a94f;
`;

const TakeoutIcon = (props) => {
	return (
		<Column alignItems="center" margin={props.margin}>
			<Img {...props} src={props.icon} />
			<H1>{props.text}</H1>
		</Column>
	);
};

export default TakeoutIcon;
