import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";
import ViaTouchLogo from "./ViaTouchLogo";
import FooterNav from "./FooterNav";
import ContactInfo from "./ContactInfo";

const Background = styled.div`
	background-color: #222421;
	color: white;
	padding: 2% 0 0 10%;
	@media only screen and (max-width: 1292px) {
		padding: 1em 0;
	}
`;

const Footer = (props) => {
	const isBigScreen = useMediaQuery({ query: "(min-width: 868px)" });

	const renderFullView = () => {
		return (
			<Row width="100%">
				<ViaTouchLogo
					logo={props.getVicki}
					alt={props.getVickiAlt}
					margin="0 2em 0 0"
				/>
				<FooterNav />
				<ContactInfo logo={props.viatouchLogo} alt={props.viatouchAlt} />
			</Row>
		);
	};

	const renderMobileView = () => {
		return (
			<Column justifyContent="center" alignItems="center" width="100%">
				<ContactInfo logo={props.viatouchLogo} alt={props.viatouchAlt} />
				<FooterNav />
			</Column>
		);
	};

	return (
		<Background>
			{isBigScreen ? renderFullView() : renderMobileView()}
		</Background>
	);
};

export default Footer;
