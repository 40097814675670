import axios from "axios";
const baseURL = "/api/loyalty/vicki";

const getBanner = async () => {
	const request = await axios.get(`${baseURL}/info`, {
		params: {
			name: "banner",
		},
	});
	return request.data;
};

const sendEmail = async (form) => {
	const request = await axios.post(`${baseURL}/contact`, {
		email: form.email,
		name: form.name,
		phone: form.phone,
		company: form.company,
		subject: form.subject,
		body: form.body || "",
	});

	return request;
};

export default { getBanner, sendEmail };
