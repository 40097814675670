import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import H2 from "../shared/H2";
import H4 from "../shared/H4";
import P from "../shared/P";

const Img = styled.img`
	@media only screen and (max-width: 725px) {
		width: 70vw;
		height: 70vw;
	}
`;

const InventoryFeature = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 936px)" });
	const renderFullView = () => {
		return (
			<Row justifyContent="space-around">
				<img src={props.inventoryImage} />
				<Column width="23%">
					<H2>Inventory Tracking</H2>
					<P>
						Save time and be more efficient by tracking your products via our
						cloud-based dashboard. You can see how many items are in the machine
						and know when you need to order. Get a handle on loss-prevention and
						shrinkage by being able to see stock levels in real time.
					</P>
					<H4>Intelli-Shelf</H4>
					<P>
						Product selection and customer ineractions are detected using a
						combination of advanced computer vision, AI, and Intelli-Shelf
						patented technology, for accurate tracking and cart computation.
					</P>
					<H4>Automated Reporting</H4>
					<P>
						Set the max shelf capacity the first time you add products to a
						VICKI Kiosk. When a shelf hits a designated "refill level" it will
						send an automated notification of the required inventory needed to
						fill the machine.
					</P>
				</Column>
			</Row>
		);
	};

	const renderMobileView = () => {
		return (
			<Row wrap="wrap" justifyContent="space-around">
				<Column padding="1em 1em">
					<H2>Inventory Tracking</H2>
					<P>
						Save time and be more efficient by tracking your products via our
						cloud-based dashboard. You can see how many items are in the machine
						and know when you need to order. Get a handle on loss-prevention and
						shrinkage by being able to see stock levels in real time.
					</P>
					<H4>Intelli-Shelf</H4>
					<P>
						Product selection and customer interactions are detected using a
						combination of advanced computer vision, AI, and Intelli-Shelf
						patented technology, for accurate tracking and cart computation.
					</P>
					<H4>Automated Reporting</H4>
					<P>
						Set the max shelf capacity the first time you add products to a
						VICKI Kiosk. When a shelf hits a designated "refill level" it will
						send an automated notification of the required inventory needed to
						fill the machine.
					</P>
				</Column>
				<Img src={props.inventoryImage} />
			</Row>
		);
	};

	return <div>{isMobile ? renderMobileView() : renderFullView()}</div>;
};

export default InventoryFeature;
