import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Nav = styled(Link)`
	text-decoration: none;
	color: black;
	font-size: 13px;
	letter-spacing: 0.5px;
	cursor: pointer;
	padding: ${(props) => props.padding};
`;

const NavLink = (props) => {
	return (
		<Nav
			{...props}
			to={props.to}
			onClick={() => {
				if (typeof props.closeMenu === "function") {
					props.closeMenu();
				}
			}}
		>
			{props.children}
		</Nav>
	);
};

export default NavLink;
