import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Column from "./Column";
import H2 from "../shared/H2";
import P from "../shared/P";

const Img = styled.img`
	width: 1439px;
	height: 598px;
	@media only screen and (max-width: 1440px) {
		width: auto;
		height: auto;
	}
	@media only screen and (max-width: 1210px) {
		width: 100vw;
		height: auto;
	}
`;

const NavLink = styled(Link)`
	color: #82c141;
	text-decoration: underline;
`;

const TextContainer = styled.div`
	display: flex;
	justify-content: center;
	position: absolute;
	margin-top: -150px;
	margin-left: 100px;
	@media only screen and (max-width: 1140px) {
		// margin-top: -7em;
		margin: 0;
		position: relative;
	}
`;

const AgeFeature = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
	return (
		<Column alignItems="center">
			<TextContainer>
				<Column
					alignItems="center"
					width={!isMobile && "40%"}
					padding={isMobile && "1em 1em"}
				>
					<H2>Age Verification</H2>
					<P>
						Authenticate customers' ages so you can be assured that your
						products can safely land in the appropriate hands. This is made
						possible with the{" "}
						<NavLink to="vicki_verify">VICKI Verify Program</NavLink>.
					</P>
				</Column>
			</TextContainer>
			<Img src={props.ageImage} />
		</Column>
	);
};

export default AgeFeature;
