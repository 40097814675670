import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
const ImgContainer = styled.div`
	height: 170px;
	width: 170px;
`;

const H1 = styled.h1`
	font-size: 24px;
	font-weight: 600;
	letter-spacing: 0.96px;
	color: #88a94f;
	margin: 0 0 1em 0;
`;

const P = styled.p`
	margin: 0;
	width: 300px;
	align-self: flex-start;
	letter-spacing: 0.71px;
`;

const VickiVerifyExample = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 915px)" });

	const renderFullView = () => {
		return (
			<Row justifyContent="center" width="100%">
				<Column alignItems="center" margin={!isMobile && "0 10em 0 0"}>
					<ImgContainer>
						<img width="162" height="157" src={props.antiTheft} />
					</ImgContainer>
					<H1>Eliminate Credit Card Fraud</H1>
					<P>
						Eliminate the ability for criminals to use stolen cards by
						confirming the card belongs to the consumer
					</P>
				</Column>
				<Column alignItems="center" width="270px">
					<ImgContainer>
						<img width="170" height="170" src={props.IDVerified} />
					</ImgContainer>
					<H1>Verify Customers Age</H1>
					<P>
						After qualifying the customer we can confirm their age and provide
						access to age-gated products
					</P>
				</Column>
			</Row>
		);
	};

	const renderMobileView = () => {
		return (
			<Column justifyContent="center" alignItems="center">
				<Column alignItems="center">
					<ImgContainer>
						<img width="162" height="157" src={props.antiTheft} />
					</ImgContainer>
					<H1>Eliminate Credit Card Fraud</H1>
					<P>
						Eliminate the ability for criminals to use stolen cards by
						confirming the card belongs to the consumer
					</P>
				</Column>
				<Column alignItems="center">
					<ImgContainer>
						<img width="170" height="170" src={props.IDVerified} />
					</ImgContainer>
					<H1>Verify Customers Age</H1>
					<P>
						After qualifying the customer we can confirm their age and provide
						access to age-gated products
					</P>
				</Column>
			</Column>
		);
	};

	return <div>{isMobile ? renderMobileView() : renderFullView()}</div>;
};

export default VickiVerifyExample;
