import axios from "axios";
const baseURL = "/api/recaptcha";
const verifyToken = async (token) => {
	const request = await axios.post(`${baseURL}/verify`, {
		response: token,
	});
	return request.data;
};

export default { verifyToken };
