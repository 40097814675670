import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const buttonStyle = css`
	text-decoration: none;
	cursor: pointer;
	width: 195px;
	height: 44px;
	border-radius: 4px;
	background-color: ${(props) => props.color || "black"};
	color: ${(props) => {
		switch (props.color) {
			case "white":
				return "black";
			default:
				return "white";
		}
	}};
	font-size: 20.4px;
	letter-spacing: 1.28px;
	text-align: center;
	line-height: 44px;
	align-self: ${(props) => props.alignSelf};
	margin: ${(props) => props.margin};
`;

const Btn = styled.a`
	${buttonStyle}
`;

const NavBtn = styled(Link)`
	${buttonStyle}
`;

const Button = (props) => {
	if (props.clickable) {
		return (
			<Btn
				{...props}
				onClick={() => {
					if (typeof props.click === "function") {
						props.click();
					}
				}}
			>
				{props.children}
			</Btn>
		);
	}
	if (props.link) {
		return (
			<NavBtn
				{...props}
				to={props.to}
				onClick={() => {
					if (typeof props.click === "function") {
						props.click();
					}
				}}
			>
				{props.children}
			</NavBtn>
		);
	}
	if (props.file) {
		return (
			<Btn {...props} href={props.file}>
				{props.children}
			</Btn>
		);
	}

	return <Btn {...props}>{props.children}</Btn>;
};

export default Button;
