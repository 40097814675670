import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import ExampleIcon from "./ExampleIcon";
import Button from "./Button";

const Header = styled.span`
	font-size: 36px;
	font-weight: 600px;
	letter-spacing: 1.29px;
	color: #88a94f;
	padding: 1em;
`;

const UVCFeatures = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });

	return (
		<Column alignItems="center">
			<Header>Promote Social Distancing with this Line-Busting Kiosk</Header>
			<Row wrap="wrap" justifyContent={isSmallScreen ? "center" : ""}>
				{Object.keys(props.firstRowImages).map((text) => (
					<ExampleIcon
						key={text}
						image={props.firstRowImages[text]}
						caption={text}
					/>
				))}
			</Row>
			<Row wrap="wrap" justifyContent={isSmallScreen ? "center" : ""}>
				{Object.keys(props.secondRowImages).map((text) => (
					<ExampleIcon
						key={text}
						image={props.secondRowImages[text]}
						caption={text}
					/>
				))}
			</Row>
			<Button margin="3em 0 3em 0" link="true" to="features">
				All Features
			</Button>
		</Column>
	);
};

export default UVCFeatures;
