import React from "react";
import VickiFront from "../../assets/images/vicki_front_hello.png";
import Row from "../../components/Row";
import ContactDiv from "../../components/ContactDiv";

const ContactUs = (props) => {
	return (
		<Row justifyContent="center">
			<ContactDiv VickiFront={VickiFront} />
			{/* <img style={{ marginTop: "10em", marginLeft: "auto" }} src={VickiFront} /> */}
		</Row>
	);
};

export default ContactUs;
