import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import ViaTouchLogo from "./ViaTouchLogo";
import Row from "./Row";
import Column from "./Column";

const Ul = styled.ul`
	list-style-type: none;
	margin-left: auto;
	margin-right: 25em;

	@media only screen and (max-width: 1292px) {
		margin-right: 0;
		padding-right: 5em;
	}
`;
const Li = styled.li`
	margin-bottom: 1em;
`;

const TextDiv = styled.span`
	font-size: 14px;
`;

const ContactInfo = (props) => {
	const isBigScreen = useMediaQuery({ query: "(min-width: 868px)" });

	const renderFullView = () => {
		return (
			<Ul>
				<Li>
					<ViaTouchLogo logo={props.logo} alt={props.alt} margin="0 0 2em 0" width="132px" height="50px"/>
				</Li>
				<Li>10505 Roselle Street, Ste 200,</Li>
				<Li>San Diego, California 92121</Li>
				<Li>info@viatouchmedia.com</Li>
				<Li>1-866-942-0804</Li>
			</Ul>
		);
	};

	const renderMobileView = () => {
		return (
			<Row padding="2em 4em 0 0" alignItems="center">
				<ViaTouchLogo logo={props.logo} alt={props.alt} padding="0 1em 0 0" width="132px" height="50px"/>
				<Column>
					<TextDiv>10505 Roselle Street, Ste 200,</TextDiv>
					<TextDiv>San Diego, California 92121</TextDiv>
					<TextDiv>info@viatouchmedia.com</TextDiv>
					<TextDiv>1-866-942-0804</TextDiv>
				</Column>
			</Row>
		);
	};

	return <div>{isBigScreen ? renderFullView() : renderMobileView()}</div>;
};

export default ContactInfo;
