import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";
import H2 from "../shared/H2";
import H4 from "../shared/H4";
import P from "../shared/P";

const StyledLink = styled(Link)`
	@media only screen and (max-width: 1000px) {
		align-self: center;
	}
`;

const Img = styled.img`
	@media only screen and (max-width: 1000px) {
		align-self: center;
	}
	@media only screen and (max-width: 810px) {
		width: 70vw;
		height: auto;
	}
`;

const UVCFeature = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 832px)" });
	return (
		<Row
			wrap="wrap"
			background="#f1f0ed"
			justifyContent="space-around"
			margin="2em 0 0 0"
		>
			<Column width={!isMobile && "30%"} padding={isMobile && "1em 1em"}>
				<H2>Touch Free | Self-Sanitizing</H2>
				<H4>QR Code & Contactless Payments</H4>
				<P>
					Consumers can pay and access any VICKI Kiosk by scanning a QR code or
					using Tap-To-Pay including ApplePay.
				</P>
				<H4>Foot Door-Opener</H4>
				<P>
					Customers can open a VICKI Kiosk without touching the machine. Once
					open they are free to shop safely.
				</P>
				<H4>UV-C Sanitizing Technology</H4>
				<P>
					VICKI will automatically clean the products after each customer. UV
					light is a reliable, well-studied antimicrobial technology. It works
					primarily by destroying the DNA inside bacteria, viruses, and fungi.
					The high-energy portion of the UV spectrum called UV-C is most
					effective.
				</P>
			</Column>
			<StyledLink to="uvc">
				<Img src={props.uvcImage} />
			</StyledLink>
		</Row>
	);
};

export default UVCFeature;
