import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./casestudy.css";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";
import Button from "./Button";
import { default as Modal } from "./StyledModal";
import P from "../shared/P";
import Link from "../shared/Link";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const ImgContainer = styled.div`
	padding-top: 2em;
`;

const Header = styled.div`
	font-size: 28px;
	font-weight: 600;
	line-height: 1.46;
	letter-spacing: 1.08px;
	color: #aad264;
	margin-bottom: 1em;
`;

const Img = styled.img`
	width: ${(props) => props.width || "663px"};
	height: ${(props) => props.height || "407px"};
	padding-right: 10em;

	@media only screen and (max-width: 1090px) {
		padding-right: 0;
	}

	@media only screen and (max-width: 920px) {
		display: none;
		width: 0;
		height: 0;
	}
`;

const DocumentStyle = {
	alignSelf: "center",
};

const CaseStudy = (props) => {
	const [openModal, setOpenModal] = useState(false);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const isMobile = useMediaQuery({ query: "(max-width: 920px)" });

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages({ numPages });
	};

	const getWidth = () => {
		if (isMobile) {
			return "70%";
		} else {
			return "40%";
		}
	};

	return (
		<Row background="#212121" justifyContent="center" height="auto">
			<Modal
				header={props.case}
				modalOpen={openModal}
				setModalOpen={() => setOpenModal()}
				width="70vw"
				height="70vh"
			>
				<Document
					className="center-document"
					file={props.pdf}
					onLoadSuccess={onDocumentLoadSuccess}
				>
					<Page className="react-pdf__Page__canvas" pageNumber={pageNumber} scale={2} />
				</Document>
				<Button file={props.pdf} alignSelf="center" margin="2em 0">
					Download PDF
				</Button>
			</Modal>
			<ImgContainer>
				<Img src={props.image} />
			</ImgContainer>
			<Column
				padding={!isMobile && "0 3em 0 0"}
				width={getWidth()}
				alignItems="center"
			>
				<Header>Case Studies</Header>
				<P color="white">{props.case}</P>
				<P color="white">{props.summary}</P>
				<Link
					onClick={() => {
						setOpenModal(true);
					}}
				>
					Learn More
				</Link>
			</Column>
		</Row>
	);
};

export default CaseStudy;
