import React from "react";
import { useMediaQuery } from "react-responsive";
import { slide as Menu } from "react-burger-menu";
import styled from "styled-components";
import ViaTouchLogo from "./ViaTouchLogo";
import Button from "./Button";
import Row from "./Row";
import Banner from "./Banner";
import NavLink from "./NavLink";

var styles = {
	bmBurgerButton: {
		position: "fixed",
		width: "36px",
		height: "30px",
		left: "85vw",
		top: "1em",
	},
	bmBurgerBars: {
		background: "#373a47",
	},
	bmBurgerBarsHover: {
		background: "#a90000",
	},
	bmCrossButton: {
		height: "24px",
		width: "24px",
	},
	bmCross: {
		background: "#bdc3c7",
	},
	bmMenuWrap: {
		position: "fixed",
		height: "100%",
	},
	bmMenu: {
		background: "#212121",
		padding: "2.5em 1.5em 0",
		fontSize: "1.15em",
	},
	bmMorphShape: {
		fill: "#373a47",
	},
	bmItemList: {
		display: "flex",
		flexDirection: "column",
		color: "white",
		padding: "0.8em",
	},
	bmItem: {
		display: "inline-block",
		color: "white",
	},
	bmOverlay: {
		background: "rgba(0, 0, 0, 0.3)",
	},
};

const NavBar = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #fafafa;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

const Link = styled.span`
	text-decoration: none;
	color: black;
	font-size: 13px;
	letter-spacing: 0.5px;
	cursor: pointer;
`;

const ExternalLink = styled.a`
	color: #88a94f;
`;

const getLink = (link) => {
	if (typeof link === "object") {
		return link;
	} else {
		return (
			<NavLink key={link} to={link.split(" ").join("_").toLowerCase()}>
				{link}
			</NavLink>
		);
	}
};

const Navigation = (props) => {
	const isBigScreen = useMediaQuery({ query: "(min-width: 1290px)" });
	const renderNavBar = () => {
		return (
			<NavBar>
				<Row alignItems="center">
					<ViaTouchLogo
						logo={props.logo}
						alt={props.alt}
						padding="1% 0 1% 2%"
						width="167px"
						height="52px"
					/>
					<Row width="100%" justifyContent="space-evenly" alignItems="center">
						{props.navigation.map((nav) => getLink(nav))}
						<Button link="true" to="contact_us">
							Contact Us
						</Button>
						<ExternalLink href={props.externalLink}>
							{props.externalLinkText}
						</ExternalLink>
					</Row>
				</Row>
				<Banner
					showBanner={props.showBanner}
					setShowBanner={() => props.setShowBanner()}
				>
					{props.banner}
				</Banner>
			</NavBar>
		);
	};

	const renderHamburger = () => {
		return (
			<div>
				<NavBar>
					<ViaTouchLogo
						logo={props.logo}
						alt={props.alt}
						padding="1% 0 1% 2%"
						width="167px"
						height="52px"
					/>
					<Banner
						showBanner={props.showBanner}
						setShowBanner={() => props.setShowBanner()}
					>
						{props.banner}
					</Banner>
				</NavBar>
				<Menu
					styles={styles}
					disableAutoFocus
					isOpen={props.menuOpen}
					onStateChange={(state) => props.setMenuOpen(state.isOpen)}
				>
					{props.navigation.map((nav) => getLink(nav))}
				</Menu>
			</div>
		);
	};

	return <div>{isBigScreen ? renderNavBar() : renderHamburger()}</div>;
};

export default Navigation;
