import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Banner from "../../assets/images/banner.png";
import BannerLower from "../../assets/images/banner_lower.png";
import About from "../../assets/videos/about.mp4";
import uvcMachine from "../../assets/images/UVC_Machine.png";
import ageVerification from "../../assets/images/age_verification.png";
import extend from "../../assets/images/extend.png";
import lossPrevention from "../../assets/images/loss_prevention.png";
import merchandise from "../../assets/images/merchandise.png";
import retail from "../../assets/images/retail.png";
import vickiPlatform from "../../assets/images/VICKI_platform.png";
import VickiText from "../../assets/images/vicki_text.png";
import aboutThumbnail from "../../assets/images/about_thumb.png";
import Hero from "../../assets/images/hero.png";
import VICKILearn from "../../assets/videos/VICKI.mp4";

import HomeBanner from "../../components/HomeBanner";
import Column from "../../components/Column";
import WideImg from "../../components/WideImg";
import Video from "../../components/Video";
import SmartSecureCustomizable from "../../components/SmartSecureCustomizable";
import StoreDiv from "../../components/StoreDiv";
import ExamplesDiv from "../../components/ExamplesDiv";
import DetailsDiv from "../../components/DetailsDiv";
import Hr from "../../components/Hr";
import Button from "../../components/Button";
import { default as Modal } from "../../components/StyledModal";

const Home = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1146px)" });
	const isMediumScreen = useMediaQuery({ query: "(max-width: 1902px)" });
	const modalHeader = () => {
		return (
			<span>
				<span style={{ paddingRight: "0.4em" }}>Learn About</span>
				<img src={VickiText} />
			</span>
		);
	};

	const modalWidth = () => {
		if (isSmallScreen) {
			return "95vw";
		}
		if (isMediumScreen) {
			return "58vw";
		}
	};

	const modalHeight = () => {
		if (isMobile) {
			return "52vh";
		}
	};

	return (
		<div>
			<Column alignItems="center">
				<Modal
					header={modalHeader()}
					// modalContentHeader="Learn About VICKI"
					modalOpen={props.isHomeModalOpen}
					setModalOpen={() => props.setHomeModalOpen()}
					width={modalWidth()}
					height={modalHeight()}
					contentWidth={isMobile && "100%"}
				>
					<div
						style={{
							padding: isMobile && "0 1em",
							textAlign: isMobile && "center",
						}}
					>
						<Video
							controls={true}
							autoplay={true}
							src={VICKILearn}
							// poster={aboutThumbnail}
							type="video/mp4"
							width="100%"
							height="100%"
							margin="2em 0"
						/>
					</div>
					<Button
						alignSelf="center"
						margin="3em 0 2em 0"
						clickable
						// link="true"
						// to="uvc"
						click={() => props.setHomeModalOpen(false)}
					>
						Close
					</Button>
				</Modal>
				{isMobile ? (
					<HomeBanner banner={Hero} />
				) : (
					<WideImg src={Banner} alt="Meet VICKI" margin="5em 0 0 0" />
				)}
				<Video
					controls={true}
					// autoplay={true}
					src={About}
					poster={aboutThumbnail}
					type="video/mp4"
					width="50%"
					height="50%"
					margin="2em 0"
				/>
				<SmartSecureCustomizable />
				<StoreDiv src={uvcMachine} alt="VICKI Machine with UV-C" />
				<WideImg src={BannerLower} alt="VICKI Examples" />
				<ExamplesDiv />
				<Hr />
				<DetailsDiv
					ageVerification={ageVerification}
					extend={extend}
					lossPrevention={lossPrevention}
					merchandise={merchandise}
					retail={retail}
					vickiPlatform={vickiPlatform}
				/>
			</Column>
		</div>
	);
};

export default Home;
