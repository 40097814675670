import React from "react";
import styled from "styled-components";
import Column from "./Column";

const Img = styled.img`
	width: 100px;
	height: 100px;
`;

const Employee = (props) => {
	return (
		<Column width="190px" alignItems="center">
			<Img src={props.employeeImage} />
			<span>{props.name}</span>
			<span>{props.title}</span>
		</Column>
	);
};

export default Employee;
