import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import P from "../shared/P";
import Header from "../shared/Header";
import SubHeader from "../shared/SubHeader";
import bloomberg from "../assets/images/Bloomberg-logo-.png";
import cheddar from "../assets/images/cheddar-logo-1.png";
import crains from "../assets/images/crains.png";
import forbes from "../assets/images/Forbes.png";
import fox from "../assets/images/FoxBusiness.png";
import vendingTimes from "../assets/images/vending_times.jpeg";
import gitex from "../assets/images/gitex.jpg";
import gitexPdf from "../assets/VICKI_GITEX_2020.pdf";
import MediaKit from "../assets/ViaTouch_Media_Kit.pdf";
import cnn from "../assets/images/cnn.png"

const ImgContainer = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 300px;
	max-height: 200px;
	padding: 5em;
`;

const Download = styled.a`
  width: 196px;
  height: 34px;
  border-radius 4px;
  background-color: black;
  color: white;
  align-self: center;
  font-size: 20.4px;
  letter-spacing: 1.28px;
  line-height: 34px;
  margin-bottom: 1em;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
`;

const DownloadCaption = styled.span`
	font-size: 20px;
	font-weight: 600;
	letter-speciaing: 0.75px;
	color: #88a94f;
	align-self: center;
	margin-bottom: 2em;
`;

const MediaPressDiv = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 942px)" });
	return (
		<Column width="90%" alignItems="flex-start">
			<Header>ViaTouch Media Inc.</Header>
			<SubHeader>Media & Press</SubHeader>
			<P>
				You might have seen us in the media. If not feel free to check out some
				of the things they have been saying about ViaTouch and VICKI.
			</P>
			<Row wrap="wrap" alignSelf="center" justifyContent="center">
				<ImgContainer href="https://www.cnn.com/2022/11/14/business/subway-grab-and-go-fridges/index.html">
					<img width="100%" src={cnn} />
				</ImgContainer>
			</Row>
			<Row wrap="wrap" alignSelf="center" justifyContent="center">
				<ImgContainer href="https://www.crainsnewyork.com/article/20170416/SMALLBIZ/170419909/tom-murn-s-high-tech-vending-machine-could-compete-with-amazon-go-for-to-be-the-convenience-store-of-the-future-a">
					<img width="100%" src={crains} />
				</ImgContainer>
				<ImgContainer href="https://www.forbes.com/sites/forbestreptalks/2017/02/16/a-vending-machine-veteran-believes-his-prototypes-can-beat-amazon-go-at-grab-and-go-shopping/#fe0191d4c4d7">
					<img width="100%" src={forbes} />
					{/* <img width="416" height="109" src={forbes} /> */}
				</ImgContainer>
			</Row>
			<Row wrap="wrap" alignSelf="center" justifyContent="center">
				<ImgContainer href="https://www.foxbusiness.com/features/technology-advances-cashless-vending-machines">
					<img width="100%" src={fox} />
					{/* <img width="384" height="152" src={fox} /> */}
				</ImgContainer>
				<ImgContainer href="https://www.vendingtimes.com/news/tom-murns-smart-shelf-lisa-vending-machine-enters-production-in-april/">
					<img width="100%" src={vendingTimes} />
					{/* <img width="556" height="126" src={vendingTimes} /> */}
				</ImgContainer>
			</Row>
			<Row wrap="wrap" alignSelf="center" justifyContent="center">
				{/* <ImgContainer href="https://bloom.bg/2nFZKqw">
					<img width="100%" src={bloomberg} />
				</ImgContainer> */}
				<ImgContainer href={gitexPdf}>
					<img width="100%" src={gitex} />
				</ImgContainer>
				<ImgContainer href="http://bit.ly/2lHkYon">
					<img width="100%" src={cheddar} />
					{/* <img width="479" height="348" src={cheddar} /> */}
				</ImgContainer>
			</Row>
			<Download href={MediaKit} download="ViaTouch_Media_Kit.pdf">
				Download
			</Download>
			<DownloadCaption>MediaKit</DownloadCaption>
		</Column>
	);
};

export default MediaPressDiv;
