import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  text-align: center;
  padding: 20px;
  background-color: #343a40;
  color: white;
  font-size: 14px;
`;

const Footer = () => (
    <FooterContainer>
        © 2024 ViaTouch Media, Inc. All rights reserved.
    </FooterContainer>
);

export default Footer;
