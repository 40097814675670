import React from "react";
import { useMediaQuery } from "react-responsive";
import VickiText from "../../assets/images/vicki_text.png";
import VickiFront from "../../assets/images/ViaTouch_Front_fullshelf.png";
import CVFace from "../../assets/images/cv_face.png";
import CVHands from "../../assets/images/cv_hands.png";
import CVProducts from "../../assets/images/cv_products.png";
import Row from "../../components/Row";
import Column from "../../components/Column";
import CVDiv from "../../components/CVDiv";
import CVDetails from "../../components/CVDetails";

const ComputerVision = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1030px)" });
	const CVInfo = [
		[
			"Computer Vision",
			CVProducts,
			[
				"Product Recognition",
				"Inventory Management",
				"Flagging Switches in Planogram",
				"Identifying Foreign Objects",
			],
		],
		[
			"Gesture & Product Tracking",
			CVFace,
			[
				"Hand Detection",
				"Hand Tracking",
				"Inventory Count",
				"Detect Suspicious Activity",
			],
		],
		[
			"Environmental Awareness",
			CVHands,
			[
				"Collect Anonymous Demographic Data",
				"Record and Study User Interactions",
				"Detect Customer and Engage",
				"Analyze Foot Traffic and Room Data",
			],
		],
	];
	return (
		<Column>
			<Row justifyContent="center" alignItems="flex-start">
				<CVDiv vickiText={VickiText} />
				<div
					style={{
						paddingTop: "10em",
						display: isSmallScreen && "none",
						width: isSmallScreen && "0",
					}}
				>
					<img height="884" width="631" src={VickiFront} />
				</div>
			</Row>
			<CVDetails
				products={CVProducts}
				hands={CVHands}
				face={CVFace}
				CVInfo={CVInfo}
			/>
		</Column>
	);
};

export default ComputerVision;
