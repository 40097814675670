import React from "react";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import P from "../shared/P";
import SubHeader from "../shared/SubHeader";

const Img = styled.img`
	width: 219px;
	height: 83px;
	margin-right: 1.5em;
	margin-left: 1.5em;
	margin-bottom: 3em;
`;

const Partners = (props) => {
	return (
		<Column width="90%" margin="5em 0 2em 0" alignItems="flex-start">
			<SubHeader>Our Partners</SubHeader>
			<P>
				We have had the privilege of working with some of the best companies in
				the world. Here are some of our partners:
			</P>
			<Row justifyContent="center" width="60%" wrap="wrap" alignSelf="center">
				{props.partners.map((partner) => (
					<Img src={partner} />
				))}
			</Row>
		</Column>
	);
};

export default Partners;
