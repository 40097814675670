import React from "react";

const OtherProducts = (props) => {
	return (
		<div>
			<div>Test</div>
		</div>
	);
};

export default OtherProducts;
