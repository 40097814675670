import React from "react";
import VickiVerifyPhone from "../../assets/images/vicki_verify_example.png";
import VickiText from "../../assets/images/vicki_text.png";
import AntiTheft from "../../assets/images/AntiTheft.png";
import IDVerified from "../../assets/images/ID_Verified.png";
import Column from "../../components/Column";
import VickiVerifyDiv from "../../components/VickiVerifyDiv";
import VickiVerifyExample from "../../components/VickiVerifyExample";

const VickiVerify = (props) => {
	return (
		<Column padding="15vh 0 5em 0" alignItems="center" justifyContent="center">
			<VickiVerifyDiv
				vickiText={VickiText}
				VickiVerifyPhone={VickiVerifyPhone}
			/>
			<VickiVerifyExample antiTheft={AntiTheft} IDVerified={IDVerified} />
		</Column>
	);
};

export default VickiVerify;
