import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";
import H2 from "../shared/H2";
import H4 from "../shared/H4";
import P from "../shared/P";

const Img = styled.img`
	@media only screen and (max-width: 1070px) {
		width: 50vw;
		height: 40vh;
	}
	@media only screen and (max-width: 840px) {
		width: 40vw;
		height: 20vh;
	}
	@media only screen and (max-width: 530px) {
		width: 50vw;
		height: 20vh;
	}
`;

const LossFeature = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 942px)" });
	return (
		<Row wrap="wrap" margin="2em 0 2em 0" justifyContent="space-around">
			<Img src={props.verifyImage} />
			<Column
				width={!isSmallScreen && "30%"}
				padding={isSmallScreen && "1em 1em"}
			>
				<H2>Loss Prevention & Computer Vision</H2>
				<P>
					VICKI uses an advanced combination of AI, Computer Vision, Machine
					Learning, and our patented Intelli-Shelf hardware (called sensor
					fusion) to combat theft in retail. An additional layer of security can
					be added by requiring customers to join the VICKI Verify Program
					before shopping.
				</P>
				<H4>Sensor Fusion</H4>
				<P>
					See which products are taken out for maximum seucirty and guard
					against theft and shrinkage. Accurate to 99.7%
				</P>
				<H4>VICKI Verify</H4>
				<P>
					Customers can become VICKI Verified through the VICKI Rewards App.
					Once their photo ID is confirmed and matched to their payment
					information, customers are considered verified.
				</P>
			</Column>
		</Row>
	);
};

export default LossFeature;
