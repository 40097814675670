import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";
import Button from "./Button";
import Video from "./Video";
import VideoModal from "./VideoModal";
import { default as Modal } from "./StyledModal";
import UVCPdf from "../assets/GetVicki_TouchFree_Sanitize.pdf";
import UVCVideo from "../assets/videos/UV-C_Animation.mp4";

const ExtraBoldItalic = styled.span`
	font-weight: 800;
	font-style: italic;
	font-size: 65px;
	line-height: 1.15;
	letter-spacing: 0.57px;

	@media only screen and (max-width: 500px) {
		text-align: center;
	}
`;

const LightH1 = styled.span`
	font-size: 60px;
	font-weight: 300;
	@media only screen and (max-width: 500px) {
		text-align: center;
	}
`;

const BoldH1 = styled.span`
	font-size: 65px;
	font-weight: 600;
	@media only screen and (max-width: 500px) {
		text-align: center;
	}
`;

const GreenContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #8dc54f;
	width: 400px;
	height: 190px;
	border-radius: 20px;
	margin-top: 3em;
	@media only screen and (max-width: 1248px) {
		margin-bottom: 2em;
	}
	@media only screen and (max-width: 500px) {
		width: 95vw;
	}
`;

const Checkmark = styled.img`
	width: 34px;
	height: 35px;
	margin-right: 1em;
`;

const BoldItalicP = styled.p`
	font-size: 18px;
	font-weight: bold;
	font-style: italic;
	margin: 0;
`;

const Img = styled.img`
	height: 835px;
	width: 711px;
	@media only screen and (max-width: 715px) {
		height: 90vw;
		width: 80vw;
	}
`;

const Li = styled.li``;
const UVCDiv = (props) => {
	const [openModal, setOpenModal] = useState(false);
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1248px)" });

	const renderFullView = () => {
		return (
			<Row justifyContent="center" alignItems="center" padding="1em 1em">
				<VideoModal
					modalOpen={openModal}
					setModalOpen={() => setOpenModal()}
					width="70vw"
					height="70vh"
				>
					<div style={{ width: "100%", height: "100%" }}>
						<Video
							controls={true}
							src={UVCVideo}
							type="video/mp4"
							width="100%"
							height="100%"
						/>
					</div>
				</VideoModal>
				<Column alignSelf="end" padding="2em 1em 0 1em">
					<ExtraBoldItalic>Virus-Killing</ExtraBoldItalic>
					<LightH1>
						with <img src={props.vickiText} />
					</LightH1>
					<LightH1>Means Items stay</LightH1>
					<BoldH1>Safe & Sanitized</BoldH1>
					<GreenContainer>
						<Column>
							<Row alignItems="center" margin="0 0 1em 0">
								<Checkmark src={props.checkmark} />
								<BoldItalicP>Protects Retailer & Customer</BoldItalicP>
							</Row>
							<Row alignItems="center" margin="0 0 1em 0">
								<Checkmark src={props.checkmark} />
								<BoldItalicP>Restores Customer Confidence</BoldItalicP>
							</Row>
							<Row alignItems="center">
								<Checkmark src={props.checkmark} />
								<BoldItalicP>Lowers Labor Costs</BoldItalicP>
							</Row>
						</Column>
					</GreenContainer>
					<Row>
						<Button margin="3em 1em 0 -0.5em" file={UVCPdf}>
							Learn More
						</Button>
						<Button
							margin="3em 0 0 1em"
							onClick={() => {
								setOpenModal(true);
							}}
						>
							View Video
						</Button>
					</Row>
				</Column>
				<Img src={props.machine} />
			</Row>
		);
	};

	const renderMobileView = () => {
		return (
			<Row justifyContent="center" alignItems="center" padding="1em 1em">
				<VideoModal
					modalOpen={openModal}
					setModalOpen={() => setOpenModal()}
					width="70vw"
					height="25vh"
				>
					<div style={{ width: "100%", height: "100%" }}>
						<Video
							controls={true}
							src={UVCVideo}
							type="video/mp4"
							width="100%"
							height="100%"
						/>
					</div>
				</VideoModal>
				<Column
					alignSelf="end"
					padding="2em 0 2em 0"
					alignItems="center"
					justifyContent="center"
				>
					<ExtraBoldItalic>Virus-Killing</ExtraBoldItalic>
					<LightH1>
						with <img src={props.vickiText} />
					</LightH1>
					<LightH1>Means Items stay</LightH1>
					<BoldH1>Safe & Sanitized</BoldH1>
					<GreenContainer>
						<Column>
							<Row alignItems="center" margin="0 0 1em 0">
								<Checkmark src={props.checkmark} />
								<BoldItalicP>Protects Retailer & Customer</BoldItalicP>
							</Row>
							<Row alignItems="center" margin="0 0 1em 0">
								<Checkmark src={props.checkmark} />
								<BoldItalicP>Restores Customer Confidence</BoldItalicP>
							</Row>
							<Row alignItems="center">
								<Checkmark src={props.checkmark} />
								<BoldItalicP>Lowers Labor Costs</BoldItalicP>
							</Row>
						</Column>
					</GreenContainer>
					<Img height="835px" width="711px" src={props.machine} />
					<Button margin="3em 0 0 0" file={UVCPdf}>
						Learn More
					</Button>
					<Button
						margin="3em 0 0 1em"
						onClick={() => {
							setOpenModal(true);
						}}
					>
						View Video
					</Button>
				</Column>
			</Row>
		);
	};

	return <div>{isSmallScreen ? renderMobileView() : renderFullView()}</div>;
};

export default UVCDiv;
