import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";
import GetVickiExample from "./GetVickiExample";

const GetVickiFeatures = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 780px)" });

	const renderFullView = () => {
		return (
			<Column margin="5em 0 5em 0" alignItems="center">
				<Row wrap="wrap" alignItems="flex-start" justifyContent="space-evenly">
					{props.firstRow.map((data) => (
						<GetVickiExample
							key={data}
							iphone={data[0]}
							header={data[1]}
							caption={data[2]}
							width="21%"
							align="center"
						/>
					))}
				</Row>
				<Row
					margin="0 10em 0 10em"
					wrap="wrap"
					alignItems="flex-start"
					justifyContent="space-evenly"
				>
					{props.examples.map((data) => (
						<GetVickiExample
							key={data}
							iphone={data[0]}
							header={data[1]}
							caption={data[2]}
							align="center"
						/>
					))}
				</Row>
			</Column>
		);
	};
	const renderMobileView = () => {
		return (
			<Column margin="5em 0 5em 0" alignItems="center">
				<Column alignItems="center">
					{props.firstRow.map((data) => (
						<GetVickiExample
							key={data}
							iphone={data[0]}
							header={data[1]}
							caption={data[2]}
							align="center"
							width="100%"
						/>
					))}
				</Column>
				<Column alignItems="center">
					{props.examples.map((data) => (
						<GetVickiExample
							key={data}
							iphone={data[0]}
							header={data[1]}
							caption={data[2]}
							align="center"
							width="100%"
						/>
					))}
				</Column>
			</Column>
		);
	};
	return <div>{isSmallScreen ? renderMobileView() : renderFullView()}</div>;
};

export default GetVickiFeatures;
