import React from "react";
import styled from "styled-components";

const HR = styled.div`
	border-top: 4.5px solid #f1f0ed;
	width: 50%;
`;

const Hr = (props) => {
	return <HR></HR>;
};

export default Hr;
