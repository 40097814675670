import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import VickiText from "../../assets/images/vicki_text.png";
import Fiserv from "../../assets/images/Fiserv_logo.png";
import Sprint from "../../assets/images/Sprint.png";
import MachineFront from "../../assets/images/ViaTouch_Front_fullshelf.png";
import Column from "../../components/Column";
import Row from "../../components/Row";
import PilotDiv from "../../components/PilotDiv";
import ContactForm from "../../components/ContactForm";
import Button from "../../components/Button";
import { default as Modal } from "../../components/StyledModal";

const Pilot = (props) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const isMobile = useMediaQuery({ query: "(max-width: 855px)" });
	const ModalHeader = () => {
		return (
			<span>
				<span style={{ paddingRight: "0.4em" }}>See what</span>
				<img src={VickiText} />
				<span style={{ paddingLeft: "0.4em" }}>can do for you</span>
			</span>
		);
	};

	const modalWidth = () => {
		if (isMobile) {
			return "95vw";
		} else {
			return "750px";
		}
	};

	const modalContentWidth = () => {
		if (isMobile) {
			return "auto";
		} else {
			return "500px";
		}
	};

	return (
		<Column padding="15vh 0 0 0">
			<Modal
				header={ModalHeader()}
				modalOpen={isModalOpen}
				setModalOpen={() => setModalOpen()}
				width={modalWidth()}
				height="80vh"
				contentWidth={modalContentWidth()}
				contentFontSize="24px"
				contentText="If you are in the San Diego area, we'd love to give you a demo."
			>
				<ContactForm />
			</Modal>
			<div>
				<PilotDiv
					vickiText={VickiText}
					companyLogo1={Fiserv}
					companyLogo2={Sprint}
					setModalOpen={(open) => setModalOpen(open)}
					MachineFront={MachineFront}
				/>
			</div>
		</Column>
	);
};

export default Pilot;
