import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Row from "./Row";

const Header = styled.span`
	font-size: 80px;
	font-weight: 300;
	line-height: 1.56;
	letter-spacing: 0.56px;
	@media only screen and (max-width: 700px) {
		padding: 0 0.5em;
		text-align: center;
		font-size: 56px;
	}
`;

const Img = styled.img`
	width: 121px;
	height: 75px;
	margin-right: 2em;
	padding-left: 3em;
	@media only screen and (max-width: 820px) {
		display: none;
	}
`;

const FeatureHeader = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
	return (
		<Row alignItems="center" justifyContent={isMobile && "center"}>
			<Img src={props.getVickiLogo} />
			<Header>{props.children}</Header>
		</Row>
	);
};

export default FeatureHeader;
