import React from "react";
import styled from "styled-components";

const Div = styled.div`
	display: flex;
	justify-content: ${(props) =>
		props.justifyContent ? props.justifyContent : "flex-start"};
	align-items: ${(props) => props.alignItems};
	align-self: ${(props) => props.alignSelf};
	margin: ${(props) => props.margin};
	// padding: ${(props) => props.padding};
	width: ${(props) => props.width || "100%"};
	min-height: ${(props) => props.height};
	flex-wrap: ${(props) => props.wrap || "nowrap"};
	background: ${(props) => props.background || ""};
`;

const Row = (props) => {
	return <Div {...props}>{props.children}</Div>;
};

export default Row;
