import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";

const Container = styled.div`
	width: ${(props) => props.width || "50%"};
	background-color: #f1f0ed;
	padding: ${(props) => props.padding};
`;

const Header = styled.span`
	font-size: 28px;
	font-weight: 600;
	line-height: 1.39;
	letter-spacing: -0.63px;
	color: #88a94f;
	padding-bottom: 1em;
`;

const TextDiv = styled.div`
	margin-top: 2em;
`;

const P = styled.p`
	font-size: 18px;
	margin: 0 0 1em 0;
`;

const ImgDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	background-color: white;
`;

const UVCExample = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1075px)" });

	const renderFullView = () => {
		return (
			<Row width="100%">
				<Container padding="3em 0 3em 6em">
					<Header>What is UV-C?</Header>
					<TextDiv>
						<P>
							<strong>About UV-C Technology:</strong> VICKI has built in UV
							technology to ensure all contact points and products are clean and
							sanitary before any customer interaction. This will ensure that no
							germs or viruses can be transferred during machine use.
						</P>

						<P>
							<strong>
								After each customer uses a VICKI Kiosk, for a period of 90
								seconds, the machine will self-sanitize the products and inside
								surfaces.
							</strong>
						</P>
						<P>
							UV light is a reliable, well-studied antimicrobial technology. It
							works primarily by destroying the DNA inside bacteria, viruses,
							and fungi. The high-energy portion of the UV spectrum called UV-C
							is most effective.
						</P>
					</TextDiv>
				</Container>
				<Container padding="1em">
					<ImgDiv>
						<img src={props.image} />
					</ImgDiv>
				</Container>
			</Row>
		);
	};

	const renderSmallView = () => {
		return (
			<Column width="100%">
				<Container padding="3em 1em" width="auto">
					<Header>What is UV-C?</Header>
					<TextDiv>
						<P>
							<strong>About UV-C Technology:</strong> VICKI has built in UV
							technology to ensure all contact points and products are clean and
							sanitary before any customer interaction. This will ensure that no
							germs or viruses can be transferred during machine use.
						</P>

						<P>
							<strong>
								After each customer uses a VICKI Kiosk, for a period of 90
								seconds, the machine will self-sanitize the products and inside
								surfaces.
							</strong>
						</P>
						<P>
							UV light is a reliable, well-studied antimicrobial technology. It
							works primarily by destroying the DNA inside bacteria, viruses,
							and fungi. The high-energy portion of the UV spectrum called UV-C
							is most effective.
						</P>
					</TextDiv>
				</Container>
				<Container  width="auto">
					<ImgDiv>
						<img src={props.image} width="100%" height="100%"/>
					</ImgDiv>
				</Container>
			</Column>
		);
	};

	return isSmallScreen ? renderSmallView() : renderFullView();
};

export default UVCExample;
