import styled from "styled-components";

const H2 = styled.span`
	font-size: 36px;
	font-weight: 600;
	letter-spacing: 0.45px;
	margin-bottom: 0.5em;
	color: #88a94f;
	align-self: ${(props) => props.alignSelf || ""};
`;

export default H2;
