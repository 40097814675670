import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";

const Text = styled.span`
	text-decoration: none;
	color: black;
	font-size: 13px;
	letter-spacing: 0.5px;
	cursor: pointer;
`;

const Ul = styled.ul`
	visibility: ${(props) => props.visibility};
	position: absolute;
	list-style-type: none;
	top: ${(props) => props.offsetTop}px;
	left: ${(props) => props.offsetLeft}px;
	padding: 0;
	border-radius: 5px;
	opacity: ${(props) => props.opacity};
	transition: 0.2s;
`;

const Li = styled.li`
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	background: #fafafa;
	transition: 0.1s;

	&:hover {
		background: #a0ba72;
	}
`;

const Arrow = styled.span`
	transform: ${(props) =>
		props.arrowDirection ? "rotate(0deg)" : "rotate(180deg)"};
	font-size: 21px;
	transition: 0.1s;
`;

const Dropdown = (props) => {
	const header = useRef();
	const [offsetTop, setOffsetTop] = useState(0);
	const [offsetLeft, setOffsetLeft] = useState(0);
	const [isOpen, setIsOpen] = useState({
		isFocused: false,
		isClicked: false,
		arrowDirection: false,
	});
	useEffect(() => {
		setOffsetTop(header.current.offsetTop + 10);
		setOffsetLeft(header.current.offsetLeft - 15);
		if (header.current) {
			window.addEventListener('resize', updateOffsets);
		}

	}, []);

	const updateOffsets = () => {
		if  (header.current) {
			setOffsetTop(header.current.offsetTop + 10);
			setOffsetLeft(header.current.offsetLeft - 15);
		} else {
      window.removeEventListener('resize', updateOffsets);
		}
	}

	return (
		<Column>
			<Text
				ref={header}
				onClick={() => {
					setIsOpen((prev) => ({
						isClicked: !prev.isClicked,
						isFocused: false,
						arrowDirection: !prev.arrowDirection,
					}));
				}}
				onFocus={() => {
					setIsOpen((prev) => ({
						...prev,
						isFocused: true,
						arrowDirection: true,
					}));
				}}
				onBlur={() => {
					setIsOpen({
						isFocused: false,
						isClicked: false,
						arrowDirection: false,
					});
				}}
				tabIndex="0"
			>
				<Row alignItems="center">
					{props.text}
					<Arrow
						arrowDirection={isOpen.isClicked || isOpen.isFocused}
						// style={arrowDirection ? arrowDown : arrowUp}
					>
						▴
					</Arrow>
				</Row>
			</Text>
			<Ul
				offsetTop={offsetTop}
				offsetLeft={offsetLeft}
				visibility={isOpen.isClicked || isOpen.isFocused ? "visible" : "hidden"}
				opacity={isOpen.isClicked || isOpen.isFocused ? "1" : "0"}
			>
				{props.menuItems.map((item) => (
					<Li key={item.props.children}>{item}</Li>
				))}
			</Ul>
		</Column>
	);
};

export default Dropdown;
