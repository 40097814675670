import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import Row from "./Row";

const H1 = styled.span`
	font-size: 36px;
	letter-spacing: 1.23px;
	color: #88a94f;
	margin: 2em 0 1em 0;
	text-align: center;
`;

const Img = styled.img`
	// padding: 0 1em 0 0;
	width: 75px;
	height: 75px;
	margin: 0 0 2em 0;
`;

const TextDiv = styled.div`
	// margin: 0 1em 2em 0;
	height: 75px;
	margin: 1em 0 0 0;
`;

const Li = styled.li`
	font-size: 14px;
	color: #88a94f;
`;

const P = styled.p`
	color: black;
	font-weight: bold;
`;

const Ul = styled.ul`
	align-self: flex-start;
`;

const DetailsDiv = (props) => {
	const isBigScreen = useMediaQuery({ query: "(min-width: 458px)" });

	return (
		<Column alignItems="center" margin="0 0 4em 0">
			<H1>Adapts to your business needs</H1>
			<Row
				wrap={!isBigScreen && "wrap"}
				justifyContent={!isBigScreen && "space-evenly"}
			>
				<Column margin={isBigScreen && "0 1em 0 0"}>
					<Img src={props.ageVerification} />
					<Img src={props.extend} />
					<Img src={props.lossPrevention} />
				</Column>
				{isBigScreen && (
					<Column
						justifyContent="space-around"
						margin={isBigScreen && "0 3em 0 0"}
					>
						<TextDiv>Age Verification</TextDiv>
						<TextDiv>Extend brand reach</TextDiv>
						<TextDiv>Loss Prevention</TextDiv>
					</Column>
				)}
				<Column margin="0 1em 0 0">
					<Img src={props.merchandise} />
					<Img src={props.retail} />
					<Img src={props.vickiPlatform} />
				</Column>
				{isBigScreen && (
					<Column
						justifyContent="space-around"
						margin={isBigScreen && "0 3em 0 0"}
					>
						<TextDiv>Merchandise & media</TextDiv>
						<TextDiv>A new retail experience</TextDiv>
						<TextDiv>VICKI Platform</TextDiv>
					</Column>
				)}
			</Row>
			<Ul>
				<Li>
					<P>First AI virtual assistant for retail</P>
				</Li>
				<Li>
					<P>Computer vision and Intelli-Shelf to track product</P>
				</Li>
				<Li>
					<P>Credit cards / NFC / biometrics for payments and access</P>
				</Li>
				<Li>
					<P>Customizable layout with dynamic lighting</P>
				</Li>
				<Li>
					<P>Rich media platform</P>
				</Li>
				<Li>
					<P>Web-based management software and reporting</P>
				</Li>
				<Li>
					<P>
						Exterior paint / branding / imagery to truly make VICKI your own
					</P>
				</Li>
			</Ul>
		</Column>
	);
};

export default DetailsDiv;
