import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Column from "./Column";
import H2 from "../shared/H2";
import H4 from "../shared/H4";
import P from "../shared/P";

const Img = styled.img`
	width: 1028px;
	height: 619px;
	align-self: center;
	@media only screen and (max-width: 1030px) {
		width: 50vw;
		height: 30vh;
	}
	@media only screen and (max-width: 930px) {
		width: 50vw;
		height: 25vh;
	}
	@media only screen and (max-width: 800px) {
		width: 50vw;
		height: 20vh;
	}
	@media only screen and (max-width: 800px) {
		width: 50vw;
		height: 20vh;
	}
	@media only screen and (max-width: 750px) {
		width: 330px;
		height: 200px;
	}
`;

const CustomizationFeature = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 1560px)" });
	return (
		<Column
			alignSelf="center"
			margin={!isSmallScreen && "3em 0 3em 0"}
			padding={isSmallScreen && "1em 1em"}
		>
			<Img src={props.machineView} />
			<H2 alignSelf="center">Customization</H2>
			<H4 color="black" marginBottom="1em">
				VICKI looks great representing your brand, while fitting seamlessly into
				your retail or reception environments.
			</H4>
			<H4>Planogram</H4>
			<P>
				Design your own planogram in order to maximize shelf space and eliminate
				dead air.
			</P>
			<H4>Media Integration</H4>
			<P>
				15-inch display, speaker array, and other interactive elements which are
				used to enhance the overall user experience.
			</P>
			<P>
				Add videos, pictures, and even audio through our simple to use web-based
				dashboard. Connect media to specific customer events during the purchase
				process including the pick up and put back of a product.
			</P>
			<H4>Branding Graphics</H4>
			<P>
				Tailor VICKI with your logo and supporting outside graphics to extend
				the reach of your brand into new areas. Get the flexibility of a popup
				experience in a brick-and-mortar environment.
			</P>
		</Column>
	);
};

export default CustomizationFeature;
