import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Row from "./Row";
import Column from "./Column";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #222421;
	width: 100%;
	color: white;
	padding: 4% 0 3% 0;
`;

const TextDiv = styled.div`
	align-self: center;
	// width: 40vw;
	// margin: 0 auto;
`;

const Header = styled.h2`
	color: #87a630;
	font-size: 2em;
	font-weight: 300;
	margin: 0;

	@media only screen and (max-width: 800px) {
		text-align: center;
		padding: 0 1em;
	}
`;

const StrongH4 = styled.h4`
	font-size: 15px;
	display: inline;

	@media only screen and (max-width: 800px) {
		margin: 0;
	}
`;

const LightH4 = styled.h4`
	font-size: 15px;
	font-weight: 300;
	display: inline;

	@media only screen and (max-width: 800px) {
		padding-bottom: 1em;
		margin: 0;
	}
`;

const SmartSecureCustomizable = (props) => {
	const isSmallScreen = useMediaQuery({ query: "(max-width: 800px)" });

	return (
		<Wrapper>
			<Row justifyContent="center" margin="0 0 2em -2%">
				<Column>
					<Header>Smart. Secure. Customizable.</Header>
					<Header>
						VICKI is your next step into the future of auto-retailing.
					</Header>
				</Column>
			</Row>
			<TextDiv>
				<Row justifyContent="center">
					<Column alignItems="flex-start" padding={isSmallScreen && "0 1em"}>
						<Row wrap="wrap">
							<StrongH4>Secure.&nbsp;</StrongH4>
							<LightH4>
								Your customers will feel confident and protected while
								interacting with her.
							</LightH4>
						</Row>
						<Row wrap="wrap">
							<StrongH4>Smart.&nbsp;</StrongH4>
							<LightH4>
								VICKI can learn your customer. VICKI not only gives information
								on any product, but also gives perks such as loyalty points!
							</LightH4>
						</Row>
						<Row wrap="wrap">
							<StrongH4>Customizable.&nbsp;</StrongH4>
							<LightH4>
								VICKI will look great representing your brand, while fitting
								seamlessly into your retail or reception environments.
							</LightH4>
						</Row>
					</Column>
				</Row>
			</TextDiv>
		</Wrapper>
	);
};

export default SmartSecureCustomizable;
