import React from "react";
import styled from "styled-components";
import Column from "./Column";

const TextInput = styled.input`
	width: ${(props) => (props.width ? props.width : "306px")};
	height: 35px;
	border-radius: 3px;
	border: ${(props) => (props.border ? "solid 2px #dbdbdb" : "none")};
	background-image: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	margin: ${(props) => (props.margin ? props.margin : "0 0 3em 0")};
	padding-left: 1em;
`;

const Label = styled.label`
	font-size: 12px;
	letter-spacing: 0.5px;
	color: #313a52;
	margin-bottom: 1em;
	text-transform: uppercase;
`;

const TextArea = styled.textarea`
	width: ${(props) => (props.width ? props.width : "306px")};
	height: 139px;
	border-radius: 3px;
	border: none;
	background-image: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	margin-bottom: 3em;
	padding-top: 1em;
	padding-left: 1em;
`;

const Submit = styled.input`
	background-color: black;
	width: 195px;
	height: 44px;
	border-radius: 4px;
	border: none;
	cursor: pointer;
	color: white;
	font-size: 20px;
	letter-spacing: 1.2px;
	align-self: ${(props) => props.alignSelf || "flex-end"};
	margin: 1em 0 1em 0;
`;

const Input = (props) => {
	const getInput = () => {
		switch (props.type) {
			case "text":
				return renderText();
			case "tel":
				return renderTel();
			case "email":
				return renderEmail();
			case "textarea":
				return renderTextArea();
			case "submit":
				return renderSubmit();
			default:
				return "Input type does not exist";
		}
	};

	const renderText = () => {
		return (
			<Column margin={props.containerMargin}>
				<Label htmlFor={props.id}>{props.labelText}</Label>
				<TextInput
					type="text"
					required={props.required}
					value={props.value}
					id={props.id}
					onChange={(e) => {
						props.setValue(e.target.value);
					}}
					{...props}
				/>
			</Column>
		);
	};

	const renderTel = () => {
		return (
			<Column>
				<Label htmlFor={props.id}>{props.labelText}</Label>
				<TextInput
					type="tel"
					required={props.required}
					id={props.id}
					maxlength="12"
					title="Invalid phone number"
					value={props.value}
					onChange={(e) => {
						props.setValue(e.target.value);
					}}
					{...props}
				/>
			</Column>
		);
	};

	const renderEmail = () => {
		return (
			<Column>
				<Label htmlFor={props.id}>{props.labelText}</Label>
				<TextInput
					type="email"
					required={props.required}
					value={props.value}
					id={props.id}
					onChange={(e) => {
						props.setValue(e.target.value);
					}}
					{...props}
				/>
			</Column>
		);
	};

	const renderTextArea = () => {
		return (
			<Column>
				<Label htmlFor={props.id}>{props.labelText}</Label>
				<TextArea
					required={props.required}
					value={props.value}
					id={props.id}
					onChange={(e) => {
						props.setValue(e.target.value);
					}}
					{...props}
					g
				/>
			</Column>
		);
	};

	const renderSubmit = () => {
		return (
			<Submit type="submit" id={props.id} value={props.value} {...props} />
		);
	};

	return getInput();
};

export default Input;
