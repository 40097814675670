import React from "react";
import getVickiLogo from "../../assets/images/getVICKI_logo.png";
import vickiFront from "../../assets/images/vicki_top.png";
import verifyImage from "../../assets/images/vicki_verify_example.png";
import inventoryImage from "../../assets/images/inventory.png";
import uvcImage from "../../assets/images/UVC_Machine.png";
import bioImage from "../../assets/images/biometrics.png";
import reportImage from "../../assets/images/report.png";
import machineView from "../../assets/images/VICKI_generic_3views.png";
import dashboardImage from "../../assets/images/dashboard_screens.png";
import machineFront from "../../assets/images/ViaTouch_Front_fullshelf.png";
import traySizes from "../../assets/images/tray_sizes.png";
import ageImage from "../../assets/images/age_verification_front.png";
import Column from "../../components/Column";
import FeatureHeader from "../../components/FeatureHeader";
import AIFeature from "../../components/AIFeature";
import LossFeature from "../../components/LossFeature";
import UVCFeature from "../../components/UVCFeature";
import InventoryFeature from "../../components/InventoryFeature";
import BioFeature from "../../components/BioFeature";
import AgeFeature from "../../components/AgeFeature";
import ReportFeature from "../../components/ReportFeature";
import CustomizationFeature from "../../components/CustomizationFeature";
import DashboardFeature from "../../components/DashboardFeature";
import SpecificationFeature from "../../components/SpecificationFeature";
import PackagesFeature from "../../components/PackagesFeature";

const Features = (props) => {
	return (
		<Column padding="15vh 0 0 0">
			<FeatureHeader getVickiLogo={getVickiLogo}>
				Standard Features
			</FeatureHeader>
			<AIFeature vickiFront={vickiFront} />
			<LossFeature verifyImage={verifyImage} />
			<UVCFeature uvcImage={uvcImage} />
			<InventoryFeature inventoryImage={inventoryImage} />
			<BioFeature bioImage={bioImage} />
			<AgeFeature ageImage={ageImage} />
			<ReportFeature reportImage={reportImage} />
			<CustomizationFeature machineView={machineView} />
			<DashboardFeature dashboardImage={dashboardImage} />
			<SpecificationFeature machineFront={machineFront} traySizes={traySizes} />
			<PackagesFeature />
		</Column>
	);
};

export default Features;
