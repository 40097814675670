import React from "react";
import styled from "styled-components";

const VideoPlayer = styled.video`
	height: ${(props) => (props.height ? props.height : "100%")};
	width: ${(props) => (props.width ? props.width : "100%")};
	margin: ${(props) => (props.margin ? props.margin : "")};
	alignself: ${(props) => (props.alignSelf ? props.alignSelf : "")};

	@media only screen and (max-width: 800px) {
		height: 100%;
		width: 100%;
	}
`;

const Video = (props) => {
	return (
		<VideoPlayer {...props} controls={props.controls} poster={props.poster} autoPlay={props.autoplay}>
			<source src={props.src} type={props.type} />
		</VideoPlayer>
	);
};

export default Video;
